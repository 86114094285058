import React, { Component } from 'react'
import { connect } from 'react-redux'
import AnimateHeight from 'react-animate-height';

class FaqsListInfo0 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            height: {
                0: 0, 1: 0, 2: 0, 3: 0, 4: 0,
            },

        }
    }

    toggle = (index) => {
        const { height } = this.state;
        height[index] = height[index] === 0 ? 'auto' : 0
        this.setState({
            height: height
        });
    };

    render() {
        // const { height } = this.state;

        return (
            <div className='faqs-list-info'>

                <div className='faq-info'>
                    <div className={this.state.height[0] === 0 ? 'open' : 'close'} onClick={() => this.toggle(0)}>
                        <div>Изисква ли се от мен да посетя офис на Амарант след дадена операция в мобилното приложение?</div>
                    </div>
                    <AnimateHeight height={this.state.height[0]} >
                        <div>
                            <p>Приложението е създадено с цел всичко да се случва дигитално. При необходимост от посещение на офис на Амарант, ще бъдете информирани и непременно ще се ангажираме с решението на Вашия случай.
                            Ако желаете да бъдете обслужени в наш офис, можете да намерите най-близкия до Вас чрез приложението, меню „Купи застраховка“ – „Намери най-близкия офис на Амарант“.</p>
                        </div>
                    </AnimateHeight>
                </div>

                <div className='faq-info'>
                    <div className={this.state.height[1] === 0 ? 'open' : 'close'} onClick={() => this.toggle(1)}>
                        <div>Какво се случва, ако съм купил застраховка с грешно попълнени данни?</div>
                    </div>
                    <AnimateHeight height={this.state.height[1]} >
                        <div>
                            <p>Сигнализирайте ни своевременно след установяване на грешката на телефон 02/492 13 53. В зависимост от това в коя информация е допусната грешката и влязла ли е в сила застраховката, има два варианта:</p>
                            <p>•Издаване на анекс за промяна на данните на полицата Ви.</p>
                            <p>•Прекратяване на полицата Ви.</p>
                        </div>
                    </AnimateHeight>
                </div>
          
                <div className='faq-info'>
                    <div className={this.state.height[2] === 0 ? 'open' : 'close'} onClick={() => this.toggle(2)}>
                        <div>Мога ли да прекратя/изтрия възлагателен договор?</div>
                    </div>
                    <AnimateHeight height={this.state.height[2]} >
                        <div>
                            <p>Можете да прекратите договора за възлагане едностранно с писмено двумесечно предизвестие на нашия имейл office@amarantbg.com или чрез друга писмена форма на административния ни адрес: гр.София ул. Хан Аспарух 5.</p>
                        </div>
                    </AnimateHeight>
                </div>
             
                <div className='faq-info'>
                    <div className={this.state.height[3] === 0 ? 'open' : 'close'} onClick={() => this.toggle(3)}>
                        <div>Как се използват личните ми данни и колко добре са защитени те?</div>
                    </div>
                    <AnimateHeight height={this.state.height[3]} >
                        <div>
                            <p>Ние в Амарант имаме строга политика относно защитата на Вашите лични данни и предприемаме необходимите мерки по всички стандарти, за да може събраната информация да е винаги криптирани и недостъпна за 3ти лица. По-подробно можете да се запознаете във Вашия профил в мобилното приложение на Амарант, секция „Правна информация“ – „Политика за поверителност“.</p>
                        </div>
                    </AnimateHeight>
                </div>

            </div>
        )
    }

}

export default connect()(FaqsListInfo0)