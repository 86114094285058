import React, { Component } from 'react'
import { connect } from 'react-redux'
import AnimateHeight from 'react-animate-height';

class FaqsListInfo5 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            height: {
                0: 0, 1: 0, 2: 0, 3: 0, 4: 0,
            },

        }
    }

    toggle = (index) => {
        const { height } = this.state;
        height[index] = height[index] === 0 ? 'auto' : 0
        this.setState({
            height: height
        });
    };

    render() {
        // const { height } = this.state;

        return (
            <div className='faqs-list-info'>

                <div className='faq-info'>
                    <div className={this.state.height[0] === 0 ? 'open' : 'close'} onClick={() => this.toggle(0)}>
                        <div>От колко компании се предлагат оферти при закупуване на застраховка?</div>
                    </div>
                    <AnimateHeight height={this.state.height[0]} >
                        <div>
                            <p>При образуването на оферта за закупуване на застраховка, приложението Амарант се допитва до 10 застрахователни компании и предоставя директно и без промени техните условия на потребителите.</p>
                        </div>
                    </AnimateHeight>
                </div>

                <div className='faq-info'>
                    <div className={this.state.height[1] === 0 ? 'open' : 'close'} onClick={() => this.toggle(1)}>
                        <div>Как се сформира персоналната оферта?</div>
                    </div>
                    <AnimateHeight height={this.state.height[1]} >
                        <div>
                            <p>Всеки клиент има уникални лични данни (шофьорски стаж, нарушения и др.), както и данни на автомобила (година на производство, обем на двигателя и др.). На база тези данни застрахователните компании могат да предоставят „персонални“ оферти.</p>
                        </div>
                    </AnimateHeight>
                </div>

                <div className='faq-info'>
                    <div className={this.state.height[2] === 0 ? 'open' : 'close'} onClick={() => this.toggle(2)}>
                        <div>Мога ли да купя застраховка на друго лице в приложението?</div>
                    </div>
                    <AnimateHeight height={this.state.height[2]} >
                        <div>
                            <p>Да, можете. Необходими са Ви разрешението на лицето и неговите лични данни.</p>
                        </div>
                    </AnimateHeight>
                </div>
            </div>
        )
    }

}

export default connect()(FaqsListInfo5)