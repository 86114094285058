import React, { Component } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { NavMain } from "../NavMain";
import MobileHeader from "./MobileHeader";
import "./styles.scss";

const Header = () => {
  return (
    <>
        <header className="header" id="header">
          <div className="container" style={{maxWidth:"1700px",paddingRight:"15px",paddingLeft:"15px"}}>
            <div className="row">
              <div className="col col-logo">
                <NavLink to="/" className="logo" style={{paddingLeft:"45px"}}>
                  <img src="/amarant-logo-blue.svg" alt="Амарант България" />
                </NavLink>
              </div>
              <div className="col col-headline">
                <h2>Свали приложението</h2>
              </div>
              <div className="col col-nav-main">
                <NavMain />
              </div>
            </div>
          </div>
        </header>
    </>
  );
};

export default connect()(Header);
