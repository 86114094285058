import React, { Component } from 'react'
import { connect } from 'react-redux'
import AnimateHeight from 'react-animate-height';

class FaqsListInfo4 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            height: {
                0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0,
            },

        }
    }

    toggle = (index) => {
        const { height } = this.state;
        height[index] = height[index] === 0 ? 'auto' : 0
        this.setState({
            height: height
        });
    };

    render() {
        // const { height } = this.state;

        return (
            <div className='faqs-list-info'>

                <div className='faq-info'>
                    <div className={this.state.height[0] === 0 ? 'open' : 'close'} onClick={() => this.toggle(0)}>
                        <div>Какво представлява Бързата проверка?</div>
                    </div>
                    <AnimateHeight height={this.state.height[0]} >
                        <div>
                            <p>При осъществяване на Бързата проверка в приложението на Амарант, потребителят може да:</p>
                            <ul className='ul'>
                                <li>Провери за наличие на глоби към КАТ и да плати връчените такива</li>
                                <li>Узнае оставащото време до изтичането на Гражданската отговорност и да закупи нова секунди след това</li>
                                <li>Разбере статуса на винетката и да закупи нова при необходимост.</li>
                            </ul>
                        </div>
                    </AnimateHeight>
                </div>
            </div>
        )
    }

}

export default connect()(FaqsListInfo4)