import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withGetScreen } from 'react-getscreen'
// import AnchorLink from 'react-anchor-link-smooth-scroll'
import { AppsDownload, SectionStatistics, BannerBAZB } from '../../components'
import { TrustUs, TrustUsMobile } from '../AboutUs'
import Slider from "react-slick";
import './styles.scss'

class AboutUs extends Component {

  render() {
    const settings = {
      className: "slider variable-width",
      dots: false,
      infinite: true,
      centerMode: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: true,
      arrows: true
    };


    return (

      <div className='aboutus'>

        <div className='section section-main'>
          <div className='container'>
            <div className='row'>
              <div className='col col-1'>
                <div>
                  <h3>КОИ СМЕ НИЕ?</h3>
                  <h4>АМАРАНТ Е ИНОВАТОР</h4>
                  <p>Затова създадохме първото мобилно приложение в България, обединяващо всичко за автомобила.</p>
                  <div className='box mobile-photo-1'>
                    <img src='/imgs/amarant-aboutus-photo.jpg' alt="Амарант колектив" />
                  </div>
                  <p className='font-size-sm'>Свалете приложението:</p>
                  <AppsDownload />
                  <div className="qr-code">
                    <p className='font-size-sm'>Или сканирайте QR код с телефона си:</p>
                    <img style={{ maxHeight: 100, border: '2px solid black' }} src='/imgs/qr.svg' alt='qr-amarant' />
                  </div>
                  <h4>АМАРАНТ Е ЛИДЕР</h4>
                  <p>в застрахователия бизнес - мнението на нашите клиенти го доказва.
                  {/* <AnchorLink href='#section-rewards'><img src='/icons/chevron-green.svg' alt='награди' className='rotate-chevron-tobottom' /></AnchorLink> */}

                  </p>
                </div>
              </div>
              <div className='col col-2'>
                <div className='box photo-1'>
                  <img src='/imgs/amarant-aboutus-photo.jpg' alt="Амарант колектив" />
                </div>
                <div className='box photo-2'>
                  <img src='/imgs/amarant-aboutus-photo-2.jpg' alt="Амарант колектив" />
                </div>
              </div>
            </div>

          </div>
        </div>

        <SectionStatistics />

        <div className='section section-partners'>
          <div className='container'>
            <div className='row'>
              <div className='col col-1'>
                <h3>НАШИТЕ ПАРТНЬОРИ</h3>
                <p>Богатият опит, доброто име и вече утвърдените ни позиции сред водещите на пазара застрахователи, ни позволяват да договаряме за нашите клиенти. Специални условия и преференциални тарифни числа по всичко видове застраховки.<br /><br />Наши основни партньори в общо и живозастраховане са :</p>
              </div>
              <div className='col col-2'>
                <div className='box partners-list'>

                  <Slider {...settings}>
                    <div><div className='partner-logo'><img src='/partners/armeetz.png' alt='Армеец' /></div></div>
                    <div><div className='partner-logo'><img src='/partners/levins.png' alt='ЛЕВ ИНС' /></div></div>
                    <div><div className='partner-logo'><img src='/partners/bginsirance.png' alt='Bulgaria Insurance' /></div></div>
                    <div><div className='partner-logo'><img src='/partners/euroins.png' alt='EUROINS' /></div></div>
                    <div><div className='partner-logo'><img src='/partners/dzi.png' alt='ДЗИ' /></div></div>
                    <div><div className='partner-logo'><img src='/partners/generali.png' alt='GENERALI' /></div></div>
                    <div><div className='partner-logo'><img src='/partners/dallbogg.png' alt='Dall Bogg' /></div></div>
                    <div><div className='partner-logo'><img src='/partners/groupama.png' alt='Groupama' /></div></div>
                    <div><div className='partner-logo'><img src='/partners/bulins.png' alt='BULINS' /></div></div>
                    <div><div className='partner-logo'><img src='/partners/ozk.png' alt='ОЗК Застраховане' /></div></div>

                    <div><div className='partner-logo'><img src='/partners/allianz.svg' alt='Allianz' /></div></div>
                    <div><div className='partner-logo'><img src='/partners/bulstrad-life.jpg' alt='Булстрад Живот' /></div></div>
                    <div><div className='partner-logo'><img src='/partners/ezok.png' alt='Европейска Застрахователна и Осигурителна Компания' /></div></div>
                    <div><div className='partner-logo'><img src='/partners/life-threatening-institute.png' alt='Животозастраховател Институт АД' /></div></div>
                    <div><div className='partner-logo'><img src='/partners/colonnade.svg' alt='Colonnade' /></div></div>
                    <div><div className='partner-logo'><img src='/partners/mondial-assistance.svg' alt='Mondial Assistance' /></div></div>
                    <div><div className='partner-logo'><img src='/partners/ozok-ins.png' alt='OZOK Ins' /></div></div>
                    <div><div className='partner-logo'><img src='/partners/saglasie.jpg' alt='Съгласие' /></div></div>
                    <div><div className='partner-logo'><img src='/partners/ccb-life.jpg' alt='ЦКБ' /></div></div>
                    <div><div className='partner-logo'><img src='/partners/Allianz-logo.png' alt='Алианз' /></div></div>
                    <div><div className='partner-logo'><img src='/partners/Coface-logo.jpg' alt='Coaface' /></div></div>
                    <div><div className='partner-logo'><img src='/partners/Colonnade-logo.png' alt='Colonnade' /></div></div>
                    <div><div className='partner-logo'><img src='/partners/Mondial-logo.jpg' alt='Mondial' /></div></div>
                    <div><div className='partner-logo'><img src='/partners/Uniqa-logo.png' alt='Uniqa' /></div></div>
                  </Slider>

                </div>
              </div>
            </div>
          </div>
        </div>


        <div className='section section-trustus'>
          <div className='container'>

            <div className='row'>
              <div className='col'>
                <h3 className='txt-center'>РАЗЧИТАЙТЕ НА НАС</h3>
                <p className='txt-center'>При нас винаги ще намерите професионална консултация базирана на вашите нужди.</p>
              </div>
            </div>

            {(this.props.isMobile()) ? <TrustUsMobile /> : <TrustUs />}

          </div>
        </div>

        <div className='section section-rewards' id='section-rewards'>
          <div className='container '>
            <div className='row'>
              <div className='col col-1'>
                <h3>НАГРАДИ</h3>
                <p>През годините сме се утвърдили на застрахователната сцена и благодарение на нашите прекрасни служители и на вас, нашите прекрасни клиенти, ние се радваме на тези отличия:</p>
                <p className='base-color bold font-size-lg'>ЗАСТРАХОВАТЕЛЕН БРОКЕР НА 2018-та година</p>
                <p className='base-color bold font-size-lg'>ЗАСТРАХОВАТЕЛЕН БРОКЕР НА 2019-та година</p>
              </div>
              <div className='col col-2'>
                <div className='box-img box-img-rewards'>
                  <img src='/imgs/amarant-aboutus-reward.jpg' alt="Награда" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <BannerBAZB />

      </div>
    )
  }
}

const options = { mobileLimit: 680, shouldListenOnResize: true }
export default connect()(withGetScreen(AboutUs, options))
