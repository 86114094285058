import { blogTypes } from '../actions'

const initialState = {
  hasNextPage: true,
  nextPage: 0,
  post: {},
  blog: [],
  blogPage: [],
  blogPageWithSize: [],
  blogPagination: []
}

const mergeblogPages = (state,payload) => {
    let newState = [...state];
    if(newState.length < 1){
      newState = [...payload]
    }
    else {
      const foundBlogs = newState.filter(x => x?._id === payload[0]?._id);
      if(foundBlogs.length < 1){
        newState = newState.concat(payload);
      }
    }
    return newState;
}

const getNextPage = (state,payload) => {
  let newState = state;
  newState = payload;
  return newState;
}

const blog = (state = initialState, { type, payload }) => {
  switch (type) {
    case blogTypes.GET_BLOG_SUCCESS:
      return {
        ...state,
        blog: payload.data
      }
    case blogTypes.GET_BLOG_PAGE_SUCCESS:
      return {
        ...state,
        blogPage: mergeblogPages(state.blogPage,payload.data)
      }
    case blogTypes.GET_BLOG_NEXT_PAGE_SUCCESS:
      return {
        ...state,
        blogPagination: state.blogPagination.concat(payload.data),
        hasNextPage:payload.hasNextPage,
        nextPage: payload.nextPage
      }
    case blogTypes.GET_BLOG_PAGE_WITH_SIZE_SUCCESS:
      return {
        ...state,
        blogPageWithSize: mergeblogPages(state.blogPageWithSize,payload.data),
        nextPage:getNextPage(state.nextPage,payload.nextPage)
      }
    case blogTypes.GET_POST_SUCCESS:
      return {
        ...state,
        post: payload
      }
    default: return state
  }
}

export default blog
