import React from "react";
import "./styles.scss";

function TitleComponent({ title, small, mobileSpecial, titleId, smallerTitle, marginleft, fullwidth, leftfullwidth, fullLine, mobile, marginBottom, first,style }) {

  return (
    <div
      className={`title-container ${mobileSpecial && "mobile-special"} ${ small && "small" } 
      ${fullwidth && "full"} ${leftfullwidth && "leftfull"} ${fullLine && "fullLine"}  ${mobile && "mobile"}  `}
      style={{marginLeft:`${marginleft}%`,marginBottom:`${marginBottom}%`}}
    >
    {smallerTitle === 1 ? <h3 id={`${titleId}`} style={{textTransform:"uppercase"}}><b>{title}</b></h3> : 
    <h1 id={`${titleId}`} className={`titleh1 ${first && "title"}`} style={style}>{title}</h1>}
    </div>
  );
}

export default TitleComponent;
