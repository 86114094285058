import React, { Component } from 'react'
import { connect } from 'react-redux'
import AnimateHeight from 'react-animate-height';

class FaqsListInfo8 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            height: {
                0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0,
            },

        }
    }

    toggle = (index) => {
        const { height } = this.state;
        height[index] = height[index] === 0 ? 'auto' : 0
        this.setState({
            height: height
        });
    };

    render() {
        // const { height } = this.state;

        return (
            <div className='faqs-list-info'>
                <div className='faq-info'>
                    <div className={this.state.height[0] === 0 ? 'open' : 'close'} onClick={() => this.toggle(0)}>
                        <div>Какви „други документи“ мога да добавям в мобилното приложение?</div>
                    </div>
                    <AnimateHeight height={this.state.height[0]} >
                        <div>
                            <p>За да използвате най-ефективно всички функционалности на мобилното приложение, можете предварително да си въведете данните за съществуващи полици, шофьорска книжка, лична карта и други документи. Мобилното приложение ще ви известява преди изтичането им.</p>
                        </div>
                    </AnimateHeight>
                </div>

                <div className='faq-info'>
                    <div className={this.state.height[1] === 0 ? 'open' : 'close'} onClick={() => this.toggle(1)}>
                        <div>Какви документи мога да запазя в приложението?</div>
                    </div>
                    <AnimateHeight height={this.state.height[1]} >
                        <div>
                            <p>Приложението Амарант може да бъде използвано за пренасяне и съхранение на застрахователните полици, шофьорските и лични документи от хартиен на електронен носител.</p>
                        </div>
                    </AnimateHeight>
                </div>
            </div>
        )
    }

}

export default connect()(FaqsListInfo8)