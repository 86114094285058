import React, { Component } from 'react'
import { connect } from 'react-redux'
import AnimateHeight from 'react-animate-height';

class FaqsListInfo10 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            height: {
                0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0,
            },

        }
    }

    toggle = (index) => {
        const { height } = this.state;
        height[index] = height[index] === 0 ? 'auto' : 0
        this.setState({
            height: height
        });
    };

    render() {
        // const { height } = this.state;

        return (
            <div className='faqs-list-info'>
                <div className='faq-info'>
                    <div className={this.state.height[0] === 0 ? 'open' : 'close'} onClick={() => this.toggle(0)}>
                        <div>Какво се случва, ако не отида на запазен час за ГТП?</div>
                    </div>
                    <AnimateHeight height={this.state.height[0]} >
                        <div>
                            <p>Не се притеснявайте, на всекиго се случва да не успее. Затова и Амарант е толерантен и санкционира само честопровиняващите се потребители.</p>
                        </div>
                    </AnimateHeight>
                </div>

                <div className='faq-info'>
                    <div className={this.state.height[1] === 0 ? 'open' : 'close'} onClick={() => this.toggle(1)}>
                        <div>Когато изтрия час за ГТП, премахва ли се веднага от графика на сервиза?</div>
                    </div>
                    <AnimateHeight height={this.state.height[1]} >
                        <div>
                            <p>При премахване на часа за ГТП в приложението, сервизът бива известяван на момента за Вашето действие.</p>
                        </div>
                    </AnimateHeight>
                </div>
                <div className='faq-info'>
                    <div className={this.state.height[2] === 0 ? 'open' : 'close'} onClick={() => this.toggle(2)}>
                        <div>Мога ли да запазя час за ГТП без да разреша достъп до моето местоположение?</div>
                    </div>
                    <AnimateHeight height={this.state.height[2]} >
                        <div>
                            <p>Да, можете. Целта е да се запази най-удобен час и място за ГТП и затова оставяме изцяло на Вас свободата за избор кога и къде.</p>
                        </div>
                    </AnimateHeight>
                </div>
                <div className='faq-info'>
                    <div className={this.state.height[3] === 0 ? 'open' : 'close'} onClick={() => this.toggle(3)}>
                        <div>Какво представлява правната помощ при щета?</div>
                    </div>
                    <AnimateHeight height={this.state.height[3]} >
                        <div>
                            <p>При нужда от съдействие от юрист при застрахователно събитие, можете да се свържете с нас. ( неоснователен отказ за изплащане на обезщетение, нужда от съдействие за събиране на необходимите документи за завеждане на събитие и други).</p>
                        </div>
                    </AnimateHeight>
                </div>
            </div>
        )
    }

}

export default connect()(FaqsListInfo10)