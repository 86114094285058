import React, { Component } from "react";
import { connect } from "react-redux";
import { getOffices } from "../../actions";
import "./styles.scss";
import { useEffect, useRef, useState } from "react";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import { MAPBOX_API_KEY } from "../../config/settings";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import { geocodeByLatLng } from "react-google-places-autocomplete";

const OfficesNew = ({ offices, getOffices }) => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(23.3154911);
  const [lat, setLat] = useState(42.6904114);
  const [zoom, setZoom] = useState(12);
  const [address, setAddress] = useState();
  const [officesCopy, setOfficesCopy] = useState([]);
  const [selOffice, setSelOffice] = useState();

  const autocomp = useRef();

  useEffect(() => {
    mapboxgl.accessToken = MAPBOX_API_KEY;
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v8",
      center: [lng, lat],
      zoom: zoom,
    });
  });

  const getInfoByAdrress = (address) => {
    geocodeByAddress(address["label"])
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        map.current.setCenter([lng, lat]);
        geocodeByLatLng({ lat: lat, lng: lng }).then((results) => {
          const name = address.value.description.split(",")[0]; // in bg
          const nameInOtherLanguage = results
            .filter((result) => result.place_id === address.value.place_id)[0]
            .formatted_address.split(",")[0]; // in eng

          setOfficesCopy(
            offices.filter(
              (office) =>
                office.name === name || office.name === nameInOtherLanguage
            )
          );
          setAddress("");
        });
      });
  };

  useEffect(() => {
    getOffices({
      onSuccess: (res) => {
        setOfficesCopy(res.payload);
        res.payload.map((office) => {
          const el = document.createElement("div");
          el.className = "marker";
          new mapboxgl.Marker(el)
            .setLngLat(office.location.coordinates)
            .setPopup(
              new mapboxgl.Popup({ offset: 25 }) // add popups
                .setHTML(`
                  <h3 style="padding-bottom:10px;text-transform:uppercase">${office.name}</h3>    
                `)
            )
            .addTo(map.current);
        });
      },
    });
  }, []);

  useEffect(() => {
    offices.map((office) => {
      const el = document.createElement("div");
      el.className = "marker";
      new mapboxgl.Marker(el)
        .setLngLat(office.location.coordinates)
        .setPopup(
          new mapboxgl.Popup({ offset: 25 }) // add popups
            .setHTML(`<h3>${office.name}</h3><p>${office.address}</p>`)
        )
        .addTo(map.current);
    });
  }, [lat]);

  const moveMapToCurrentOffice = (office) => {
    map.current.setCenter(office.location.coordinates);
  };

  const scrollTop = () => {
    if(window.innerWidth < 900){
      window.scrollTo({
        top:0,
        behavior:"smooth"
      })
    }
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="offices">
      {offices ? (
        <div
          className="section section-main"
          style={{ height: window.innerWidth < 900 && "50vh" }}
          
        >
          <div ref={mapContainer} className="map-container" />
        </div>
      ) : null}
      {offices ? (
        <div
          className="section section-offices-list"
          style={{
            paddingTop: "10px",
            marginLeft: window.innerWidth < 900 && "0%",
          }}
        >
          <div className="container-123" onClick={() => {console.log("hi")}}>
            <div className="search-form" onClick={() => {
              const inputValueBlurred = document.getElementsByClassName("css-16pw0ov-singleValue");
              if(inputValueBlurred.length > 0){
                inputValueBlurred[0].innerText = "Започнете да пишете Вашия град"
                setOfficesCopy(offices);
              }
            }}>
              <div style={{cursor:"pointer"}}>
              <GooglePlacesAutocomplete
                
                textInputProps={{
                  onSubmitEditing: () => {
                    alert("Hello");
                  },
                }}
                ref={(c) => (autocomp.current = c)}
                searchOptions={{ types: ["locality", "country"] }}
                apiKey="AIzaSyALipb3WN32r90_zyf2wk-vNrEod_JdR5Q"
                autocompletionRequest={{
                  componentRestrictions: {
                    country: ["bg"],
                  },
                  types: ["(cities)"],
                }}
                id="address"
                selectProps={{
                  onFocus:() => {const inputValueBlurred = document.getElementsByClassName("css-16pw0ov-singleValue");
                  if(inputValueBlurred.length > 0){
                    inputValueBlurred[0].innerText = "Започнете да пишете Вашия град"
                    setOfficesCopy(offices);
                  }},
                  styles: {
                    input: (provided) => ({
                      ...provided,
                      "font-family":
                        "CorsaGrotesk-Regular, Verdana, sans-serif !important",
                      "text-align": "left",
                    }),
                    option: (provided) => ({
                      ...provided,
                      "font-family":
                        "CorsaGrotesk-Regular, Verdana, sans-serif !important",
                      "text-align": "left",
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      "font-family":
                        "CorsaGrotesk-Regular, Verdana, sans-serif !important",
                      "text-align": "left",
                    }),
                  },

                  placeholder: "Започнете да пишете Вашия град",
                  address,
                  onChange: (address) => {
                    setSelOffice({});
                    setAddress(address);
                    getInfoByAdrress(address);
                  },
                }}
              />
              </div>
             
            </div>
          </div>
          <div
            className="container"
            style={{
              maxHeight: "90%",
              overflowY: "scroll",
              overflowX: "auto",
            }}
          >
            <div className="row">
              {officesCopy?.map((office, index) => (
                <div
                  key={"office-" + index}
                  id={"office-" + index}
                  className={`col `}
                  style={{ width: "100%", paddingBottom: "0px" }}
                >
                  <div
                    className={`office `}
                    onClick={() => {
                      moveMapToCurrentOffice(office);
                      setSelOffice(index);
                      scrollTop();
                    }}
                  >
                    <div
                      className={`info-address ${
                        selOffice === index && "selected"
                      }`}
                    >
                      <h4 style={{textTransform:"uppercase"}}>{office.name}</h4>
                      <p className="address">{office.address}</p>
                      <div
                        style={{
                          display: "flex",
                          marginTop: "1%",
                          flexDirection: window.innerWidth < 900 && "column",
                        }}
                      >
                        <p className="phone">
                          <span>{office.phoneNumber}</span>
                        </p>
                        <p className="email">
                          <span>
                            {office.email.length > 1 ? office.email : "---"}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => ({
  offices: state.offices.offices,
});
const mapDispatchToProps = (dispatch) => ({
  getOffices: (payload) => dispatch(getOffices(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OfficesNew);
