import React, { Component } from 'react'
import { connect } from 'react-redux'
import { BlogBox, NewsComponent } from '../../components'
import './styles.scss';
import { getBlog, getBlogPage,getBlogNextPage, getBlogPageWithSize } from '../../actions'
import { useEffect } from 'react';


const Blog = ({blogPageWithSize,getBlogPageWithSize,nextPage}) => {

  useEffect(() => {
    getBlogPageWithSize({
      page:1,
      size:10
    })
    
  },[])
  useEffect(() => {
    window.scrollTo({
      top:0,
      behavior:"smooth"
    });
  },[])
  return(<NewsComponent blogPageWithSize={blogPageWithSize} getBlogPageWithSize={getBlogPageWithSize} nextPage = {nextPage}/>)
}
const mapStateToProps = state => ({
  blog: Array.from(state.blog.blog),
  blogPage: Array.from(state.blog.blogPage),
  blogPagination: Array.from(state.blog.blogPagination),
  blogPageWithSize: Array.from(state.blog.blogPageWithSize),
  hasNextPage: state.blog.hasNextPage,
  nextPage: state.blog.nextPage,

})
const mapDispachToProps = dispatch => ({
  getBlog: payload => dispatch(getBlog()),
  getBlogPage: payload => dispatch(getBlogPage(payload)),
  getBlogNextPage: payload => dispatch(getBlogNextPage(payload)),
  getBlogPageWithSize: payload => dispatch(getBlogPageWithSize(payload)),
})
export default connect(mapStateToProps, mapDispachToProps)(Blog);
