import { combineEpics } from 'redux-observable'
import * as starter from './starter'
import * as blog from './blog'
import * as offices from './offices'


export const rootEpic = combineEpics(
  starter.loadingEpic,
  blog.getBlog,
  blog.getBlogPage,
  blog.getBlogNextPage,
  blog.getBlogPageWithSize,
  blog.getPost,
  offices.getOffices
)
