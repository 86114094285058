import React, { Component } from "react";
import "./styles.scss";

const textMap = [
  {
    title: "КАКВО Е БЪРЗА ПРОВЕРКА?",
    text: "Автоматично извличане на цялата информация за Вашия автомобил чрез сигурна връзка с държавните агенции",
  },
  {
    title: "БЕЗПЛАТНО ЛИ Е?",
    text: "Да - правете неограничен брой справки за колкото автомобили пожелаете",
  },
  
];

const secontTextMap = [
  {
    title: "ЗА КАКВО СЛУЖИ?",
    text: "Получавате информация за валидността на Гражданска отговорност, технически преглед, винетка, както и за налични глоби към КАТ",
  },
  {
    title: "КАК РАБОТИ?",
    text: "Просто сканирайте талона и всичко ще се случи автоматично",
  },
];

const FastCheck = ({ actions }) => {
  return (
    <div
      className="check-container"
      style={{
        backgroundColor: actions && "white",
        backgroundImage: actions && "none",
      }}
    >
      <div style={{ width: "100%", display: "flex", justifyContent: "center",paddingTop:actions && window.innerWidth > 900 ? "3%" : "0%" }}>
        <h1
          className={`${actions ? "line" : "noline"}`}
          style={{
            textAlign: "center",
            marginTop: "2%",
            color: actions ? "black" : "white",
            paddingTop:"2%"
          }}
        >
          {actions ? "ДЕЙСТВИЯ НА БАЗА СПРАВКАТА" : "БЪРЗА ПРОВЕРКА"}
        </h1>
      </div>

      <div
        style={{
          display: "flex",
          width: "99%",
          marginLeft:!actions && "0.5%",
          height: "97%",
          justifyContent: "space-around",
          paddingTop:actions && "1%"
        }}
      >
        {actions ? (
          <>
            <div className="left-container actions" style={{marginLeft:window.innerWidth > 900 && "1%"}}>
              <div className="tab">
                <div className="tabImage first" />
                <h3 style={{ textTransform: "uppercase",fontSize:"17px",paddingBottom:"0px" }}>
                  закупуване на гражданска отговорност
                </h3>
              </div>
              <div className="tab">
                <div className="tabImage second" />
                <h3 style={{ textTransform: "uppercase",fontSize:"17px",paddingBottom:"0px" }}>
                ЗАКУПУВАНЕ НА ДРУГИ ЗАСТРАХОВки
                </h3>
              </div>
              <div className="tab">
                <div className="tabImage third" />
                <h3 style={{ textTransform: "uppercase",fontSize:"17px",paddingBottom:"0px" }}>
                Опция за плащане на вноски по полица
                </h3>
              </div>
              <div className="tab">
                <div className="tabImage fourth" />
                <h3 style={{ textTransform: "uppercase",fontSize:"17px",paddingBottom:"0px" }}>
                подновяване на изтичаща полица
                </h3>
              </div>
            </div>
            <div className="centerImage actions"/> 
            <div className="left-container actions" style={{marginLeft:window.innerWidth > 900 && "1%"}}>
              <div className="tab">
                <div className="tabImage fifth" />
                <h3 style={{ textTransform: "uppercase",fontSize:"17px",paddingBottom:"0px" }}>
                добавяне на съществуваща полица
                </h3>
              </div>
              <div className="tab">
                <div className="tabImage sixth" />
                <h3 style={{ textTransform: "uppercase",fontSize:"17px",paddingBottom:"0px" }}>
                Напомняне при изтичане на полица
                </h3>
              </div>
              <div className="tab">
                <div className="tabImage seventh" />
                <h3 style={{ textTransform: "uppercase",fontSize:"17px",paddingBottom:"0px" }}>
                закупуване на винетка
                </h3>
              </div>
              <div className="tab">
                <div className="tabImage eighth" />
                <h3 style={{ textTransform: "uppercase",fontSize:"17px",paddingBottom:"0px" }}>
                заплащане на връчени глоби към кат
                </h3>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="left-container">
              {textMap.map((text, i) => {
                return (
                  <div className="card" style={{height:"190px"}} >
                    <div className="first">
                      <div
                        className={`card-icon ${i === 0 ? "first" : "second"}`}
                      />
                      <h3 className="card-title">{text.title}</h3>
                    </div>
                    <p style={{marginTop:"3%"}}>{text.text}</p>
                  </div>
                );
              })}
            </div>
            <div className="centerImage" />
            <div className="left-container" style={{ marginLeft: "0%",marginRight:"4%" }}>
              {secontTextMap.map((text, j) => {
                return (
                  <div className="card" style={{height:"190px"}}>
                    <div className="first">
                      <div
                        className={`card-icon ${j === 0 ? "third" : "forth"}`}
                      />
                      <h3 className="card-title">{text.title}</h3>
                    </div>
                    <p style={{marginTop:"3%"}}>{text.text}</p>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default FastCheck;
