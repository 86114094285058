import { officesTypes } from '../actions'

const initialState = {
  offices: []
}



const offices = (state = initialState, { type, payload }) => {
  switch (type) {
    case officesTypes.GET_OFFICES_SUCCESS:
      return { ...state, offices: payload }
    default: return state
  }
}

export default offices
