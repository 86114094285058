import React, { Component } from 'react'
import { connect } from 'react-redux'
import AnimateHeight from 'react-animate-height';
// import { Link } from 'react-router-dom'

class FaqsListInfo7 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            height: {
                0: 0, 1: 0, 2: 0, 3: 0, 4: 0,
            },

        }
    }

    toggle = (index) => {
        const { height } = this.state;
        height[index] = height[index] === 0 ? 'auto' : 0
        this.setState({
            height: height
        });
    };

    render() {
        // const { height } = this.state;

        return (
            <div className='faqs-list-info'>

                <div className='faq-info'>
                    <div className={this.state.height[0] === 0 ? 'open' : 'close'} onClick={() => this.toggle(0)}>
                        <div>Какви винетки мога да закупя през приложението?</div>
                    </div>
                    <AnimateHeight height={this.state.height[0]} >
                        <div>
                            <p>През приложението могат да се закупят всички видове винетка (уикенд, седмична, месечна, тримесечна, годишна) за лек автомобил и каравана.</p>
                        </div>
                    </AnimateHeight>
                </div>

                <div className='faq-info'>
                    <div className={this.state.height[1] === 0 ? 'open' : 'close'} onClick={() => this.toggle(1)}>
                        <div>От кога започва да е валидна винетката?</div>
                    </div>
                    <AnimateHeight height={this.state.height[1]} >
                        <div>
                            <p>Винетката започва да е валидна от момента на нейното закупуване или от начална бъдеща дата, която сте задали при закупуването.</p>
                        </div>
                    </AnimateHeight>
                </div>

                <div className='faq-info'>
                    <div className={this.state.height[2] === 0 ? 'open' : 'close'} onClick={() => this.toggle(2)}>
                        <div>Какво представляват връчената и невръчената глоба от КАТ?</div>
                    </div>
                    <AnimateHeight height={this.state.height[2]} >
                        <div>
                            <p>Глобите в КАТ стават активни и могат да се плащат след тяхното директно връчване от служител на КАТ, което ги разделя на:</p>
                            <ul>
                                <li>Връчени глоби – Глоби, които са вече предадени от служител на КАТ. След този момент, тези глоби са активни и очакват своето плащане в приложението Амарант.</li>
                                <li>Невръчени глоби – Глоби, които все още не са предадени от служител на КАТ и затова те са само видими в приложението, без възможност за плащането им.</li>
                            </ul>
                        </div>
                    </AnimateHeight>
                </div>

                <div className='faq-info'>
                    <div className={this.state.height[3] === 0 ? 'open' : 'close'} onClick={() => this.toggle(3)}>
                        <div>Има ли възможност приложението да не отчете съществуваща глоба, налична в системата на КАТ?</div>
                    </div>
                    <AnimateHeight height={this.state.height[3]} >
                        <div>
                            <p>Няма такава възможност тъй като приложението „Амарант“ е директен посредник между Вас и системата на КАТ. Така всички Ваши глоби, които са налични в КАТ, се визуализират при проверка в приложението.</p>
                        </div>
                    </AnimateHeight>
                </div>

            </div>
        )
    }

}

export default connect()(FaqsListInfo7)