export const officesTypes = {
  GET_OFFICES: 'offices/GET_OFFICES',
  GET_OFFICES_SUCCESS: 'offices/GET_OFFICES_SUCCESS',
  GET_OFFICES_FAIL: 'offices/GET_OFFICES_FAIL',
}

export const getOffices = payload => {
  return {
    type: officesTypes.GET_OFFICES,
    payload
  }
}
export const getOfficesSuccess = payload => {
  return {
    type: officesTypes.GET_OFFICES_SUCCESS,
    payload
  }
}
