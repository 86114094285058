import React, { Component } from 'react'
import { connect } from 'react-redux'
import AnimateHeight from 'react-animate-height';

class FaqsListInfo3 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            height: {
                0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0,
            },

        }
    }

    toggle = (index) => {
        const { height } = this.state;
        height[index] = height[index] === 0 ? 'auto' : 0
        this.setState({
            height: height
        });
    };

    render() {
        // const { height } = this.state;

        return (
            <div className='faqs-list-info'>

                <div className='faq-info'>
                    <div className={this.state.height[0] === 0 ? 'open' : 'close'} onClick={() => this.toggle(0)}>
                        <div>Трябва ли да съм клиент на Амарант, за да се регистрирам в мобилното приложение?</div>
                    </div>
                    <AnimateHeight height={this.state.height[0]} >
                        <div>
                            <p>Не е нужно предварително да сте клиент на Амарант, за да използвате безплатните услуги в платформата. Всеки човек със смартфон, поддържащ операционна система iOS или Android, може да изтегли и използва мобилното ни приложение.</p>
                        </div>
                    </AnimateHeight>
                </div>

                <div className='faq-info'>
                    <div className={this.state.height[1] === 0 ? 'open' : 'close'} onClick={() => this.toggle(1)}>
                        <div>Натискам бутона „Създай профил“, но ми излиза грешка. Как мога да си направя регистрация?</div>
                    </div>
                    <AnimateHeight height={this.state.height[1]} >
                        <div>
                            <p>Преди натискането на бутона, трябва да сте абсолютно сигурни, че сте се запознали с:</p>
                            <ul className='ul'>
                                <li>Политиката за поверителност</li>
                                <li>Условията за използване</li>
                                <li>Допълнителната информация</li>
                            </ul>
                            <p>и сте отбелязали полето, което се намира вляво от тях, за да продължите напред в приложението.</p>
                        </div>
                    </AnimateHeight>
                </div>

                <div className='faq-info'>
                    <div className={this.state.height[2] === 0 ? 'open' : 'close'} onClick={() => this.toggle(2)}>
                        <div>Мога ли да използвам регистрацията си на повече от едно мобилно устройство?</div>
                    </div>
                    <AnimateHeight height={this.state.height[2]} >
                        <div>
                            <p>Да, можете да се използвате Вашата регистрация на чужди устройства, стига да напишете 4-цифрения код, който се изпраща само на Вашето мобилно устройство. При влизане в профила Ви от друго устройство, директно се излиза от профила Ви на Вашето устройство!
</p>
                        </div>
                    </AnimateHeight>
                </div>

            </div>
        )
    }

}

export default connect()(FaqsListInfo3)