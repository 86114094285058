import React, { Component } from 'react'
import { connect } from 'react-redux'
// import { GoogleApiWrapper, Map, InfoWindow, } from 'google-map-react';
import { getOffices } from '../../actions'
import './styles.scss';

class Offices extends Component {
 
}
const mapStateToProps = state => ({
    offices: state.offices.offices
})
const mapDispatchToProps = dispatch => ({
  getOffices: (payload) => dispatch(getOffices())
})

export default connect(mapStateToProps, mapDispatchToProps)(Offices)
