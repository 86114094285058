import React, { Component } from 'react'
import { connect } from 'react-redux'
import AnimateHeight from 'react-animate-height';

class FaqsListInfo1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            height: {
                0: 0, 1: 0, 2: 0, 3: 0, 4: 0,
            },

        }
    }

    toggle = (index) => {
        const { height } = this.state;
        height[index] = height[index] === 0 ? 'auto' : 0
        this.setState({
            height: height
        });
    };

    render() {
        // const { height } = this.state;
        return (
            <div className='faqs-list-info'>

                <div className='faq-info'>
                    <div className={this.state.height[0] === 0 ? 'open' : 'close'} onClick={() => this.toggle(0)}>
                        <div>Кои са Амарант България?</div>
                    </div>
                    <AnimateHeight height={this.state.height[0]} >
                        <div>
                            АМАРАНТ Е ЛИДЕР:
                            <p> Във всички видове продукти в областта на застраховането,животозастраховането и пенсионното осигуряване. Като цяло дружеството обслужва приблизително 4 % от общия пазарен дял на застраховките реализирани от застрахователни брокери в България. Реализира успешни партньорства с всички водещи Застрахователни компании на пазара.</p>
                        </div>
                        <div>
                            АМАРАНТ Е ИНОВАТОР:
                            <p>Основна цел на брокера е през 2020 г. да наложи на пазара разработено мобилно Приложение обхващащо всички етапи на застраховането – аквизиция и ликвидация, както и други допълнителни услуги – известяване при изтичане на застраховка, винетка, Годишен технически преглед, опция за заплащане на зона за паркиране и други.</p>
                        </div>
                    </AnimateHeight>
                </div>

                <div className='faq-info'>
                    <div className={this.state.height[1] === 0 ? 'open' : 'close'} onClick={() => this.toggle(1)}>
                        <div>Защо беше създадено приложението Амарант?</div>
                    </div>
                    <AnimateHeight height={this.state.height[1]} >
                        <div>
                            <p>Мисията на мобилното приложение на Амарант е дигитализирането на всички застрахователни процеси и тяхното пренасяне в телефона на потребителя, с цел достъп до услугите без значение от място и време. Улесняването на клиентите ни е приоритет за нас и ни мотивира ежедневно да развиваме платформата, за да я превърнем в единен дигитален хъб, обслужващ всички потребности на клиентите ни относно техните автомобили и застраховки. </p>
                        </div>
                    </AnimateHeight>
                </div>

                <div className='faq-info'>
                    <div className={this.state.height[2] === 0 ? 'open' : 'close'} onClick={() => this.toggle(2)}>
                        <div>При допълнителни запитвания и препоръки относно приложението, как мога да се свържа с екипа на Амарант?</div>
                    </div>
                    <AnimateHeight height={this.state.height[2]} >
                        <div>
                            <p>При възникване на въпроси относно предлаганите от Амарант продукти и услуги, можете да се обърнете към централния офис на Амарант България на телефони:</p>
                            <ul className='ul'>
                                <li>02/492 13 53</li>
                                <li>02/492 13 55</li>
                            </ul>
                            <p>При запитвания относно приложението на Амарант и неговите функционалности, ще се радваме да получим Вашия имейл на:</p>
                            <a href='mailto:software@amarantbg.com'>software@amarantbg.com</a>
                        </div>
                    </AnimateHeight>
                </div>
            </div>
        )
    }

}

export default connect()(FaqsListInfo1)