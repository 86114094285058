import React, { Component } from 'react'
import { connect } from 'react-redux'
import AnimateHeight from 'react-animate-height';

class FaqsListInfo2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            height: {
                0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0,
            },

        }
    }

    toggle = (index) => {
        const { height } = this.state;
        height[index] = height[index] === 0 ? 'auto' : 0
        this.setState({
            height: height
        });
    };

    render() {
        // const { height } = this.state;

        return (
            <div className='faqs-list-info'>

                <div className='faq-info'>
                    <div className={this.state.height[0] === 0 ? 'open' : 'close'} onClick={() => this.toggle(0)}>
                        <div>Какво представлява мобилното приложение на Амарант?</div>
                    </div>
                    <AnimateHeight height={this.state.height[0]} >
                        <div>
                            <p>Мобилното приложение на Амарант е иновация в предлагането на застрахователни продукти и услуги за автомобила в България. Дигитализирането на всички хартиени полици, автоматично получаване на най-добрите предложения за застраховка, закупуването на винетка и плащането на глобите към КАТ са само част от възможностите, които се предоставят на потребителя.</p>
                        </div>
                    </AnimateHeight>
                </div>

                <div className='faq-info'>
                    <div className={this.state.height[1] === 0 ? 'open' : 'close'} onClick={() => this.toggle(1)}>
                        <div>Какви са функционалностите на приложението?</div>
                    </div>
                    <AnimateHeight height={this.state.height[1]} >
                        <div>
                            <p>✅ Бърза проверка и отговор на въпросите:</p>
                            <ul className='ul'>
                                <li>Дали имате глоби към КАТ?</li>
                                <li>Кога трябва да подновите Гражданската отговорност?</li>
                                <li>До кога е валидна винетката?</li>
                            </ul>
                            <p>✅ Закупуване на Гражданска отговорност, Автокаско и застраховка Имущество от 10-те водещи застрахователни компании в България.</p>
                            <p>✅ Добавяне на полица, следене на следващите дати на вноските и тяхното плащане.</p>
                            <p>✅ Закупуване на нова винетка и известие при нейното изтичане.</p>
                            <p>✅ Разбиране за невръчените глоби към КАТ и директно плащане на връчените.</p>
                        </div>
                    </AnimateHeight>
                </div>

                <div className='faq-info'>
                    <div className={this.state.height[2] === 0 ? 'open' : 'close'} onClick={() => this.toggle(2)}>
                        <div>Мога ли да заплатя за услугите онлайн?</div>
                    </div>
                    <AnimateHeight height={this.state.height[2]} >
                        <div>
                            <p>Да, можете да платите онлайн чрез VISA & Mastercard и да извършите Вашата транзакция на момента, като за всички кредитни и дебитни карти таксата е 00,00 лв. При желание, запаметете и използвайте Вашата карта  за следващи плащания!</p>
                        </div>
                    </AnimateHeight>
                </div>

                <div className='faq-info'>
                    <div className={this.state.height[3] === 0 ? 'open' : 'close'} onClick={() => this.toggle(3)}>
                        <div>Как най-бързо мога да споделя приложението?</div>
                    </div>
                    <AnimateHeight height={this.state.height[3]} >
                        <div>
                            <p>Отидете в своя профил, секция  „Съдействие“, и изберете „Покани приятел“ или копирайте и споделете следния линк: <a href='http://onelink.to/fp6xcw'>http://onelink.to/fp6xcw</a></p>
                        </div>
                    </AnimateHeight>
                </div>
            </div>
        )
    }

}

export default connect()(FaqsListInfo2)