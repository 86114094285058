export const blogTypes = {
  GET_BLOG: 'offices/GET_BLOG',
  GET_BLOG_SUCCESS: 'offices/GET_BLOG_SUCCESS',
  GET_BLOG_FAIL: 'offices/GET_BLOG_FAIL',
  GET_BLOG_PAGE: 'offices/GET_BLOG_PAGE',
  GET_BLOG_PAGE_SUCCESS: 'offices/GET_BLOG_PAGE_SUCCESS',
  GET_BLOG_PAGE_FAIL: 'offices/GET_BLOG_PAGE_FAIL',
  GET_BLOG_NEXT_PAGE: 'offices/GET_BLOG_NEXT_PAGE',
  GET_BLOG_NEXT_PAGE_SUCCESS: 'offices/GET_BLOG_NEXT_PAGE_SUCCESS',
  GET_BLOG_NEXT_PAGE_FAIL: 'offices/GET_BLOG_NEXT_PAGE_FAIL',
  GET_BLOG_PAGE_WITH_SIZE: 'offices/GET_BLOG_PAGE_WITH_SIZE',
  GET_BLOG_PAGE_WITH_SIZE_SUCCESS: 'offices/GET_BLOG_PAGE_WITH_SIZE_SUCCESS',
  GET_BLOG_PAGE_WITH_SIZE_FAIL: 'offices/GET_BLOG_PAGE_WITH_SIZE_FAIL',
  GET_POST: 'offices/GET_POST',
  GET_POST_SUCCESS: 'offices/GET_POST_SUCCESS',
  GET_POST_FAIL: 'offices/GET_POST_FAIL',
}
export const getBlog = payload => {
  return {
    type: blogTypes.GET_BLOG,
    payload
  }
}
export const getBlogSuccess = payload => {
  return {
    type: blogTypes.GET_BLOG_SUCCESS,
    payload
  }
}
export const getBlogPage = payload => {
  return {
    type: blogTypes.GET_BLOG_PAGE,
    payload
  }
}
export const getBlogPageSuccess = payload => {
  return {
    type: blogTypes.GET_BLOG_PAGE_SUCCESS,
    payload
  }
}
export const getBlogNextPage = payload => {
  return {
    type: blogTypes.GET_BLOG_NEXT_PAGE,
    payload
  }
}
export const getBlogNextPageSuccess = payload => {
  return {
    type: blogTypes.GET_BLOG_NEXT_PAGE_SUCCESS,
    payload
  }
}
export const getBlogPageWithSize = payload => {
  return {
    type: blogTypes.GET_BLOG_PAGE_WITH_SIZE,
    payload
  }
}
export const getBlogPageWithSizeSuccess = payload => {
  return {
    type: blogTypes.GET_BLOG_PAGE_WITH_SIZE_SUCCESS,
    payload
  }
}
export const getPost = payload => {
  return {
    type: blogTypes.GET_POST,
    payload
  }
}
export const getPostSuccess = payload => {
  return {
    type: blogTypes.GET_POST_SUCCESS,
    payload
  }
}
