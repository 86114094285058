import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router'
import { withRouter } from 'react-router-dom'

import { Header, Footer } from '../../components'
import { Home, AboutUs, Insurances, Offices,  Documents, FAQs, DownloadApp, Blog, Article, DownloadAppButton } from '../../screens'
import OfficesNew from '../../screens/Offices/OfficesNew'
import MobileHeader from '../Header/MobileHeader'

class Routes extends Component {
  render() {
    return (
      <div className='wrap-all' style={{width:window.innerWidth > 900 && "100vw"}}>
        {window.innerWidth < 900 ? <MobileHeader/> : <Header />}
        <Switch>
          <Route exact path='/' component={Home} />
          <Route path='/about-us' component={AboutUs} />
          <Route path='/subscriptions' component={Insurances} />
          <Route path='/offices' component={OfficesNew} />
          <Route path='/blog' component={Blog} />
          <Route path='/article/:id' component={Article} />
          <Route path='/documents' component={Documents} />
          <Route path='/faqs' component={FAQs} />
          <Route path='/download-app' component={DownloadAppButton} />
        </Switch>
        <Footer />

        {/* </CSSTransition>
        </TransitionGroup> */}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return { loading: state.starter.loading }
}

export default withRouter(connect(mapStateToProps)(Routes))
