import React from 'react'

import './styles.scss'


class Statistics extends React.Component {
  render() {
    return (
      <div className="section section-statistics">
        <div className="container">
          <div className="row row-title">
            <div className='col'><h3>ЦИФРИТЕ ГОВОРЯТ САМИ</h3></div>
          </div>

          <div className='box'>
            <div className="row">
              <div className="col">
                <small>&nbsp;</small>
                <h3>16</h3>
                <p>ГОДИНИ  ОПИТ</p>
              </div>
              <div className="col">
                <small>НАД</small>
                <h3>230</h3>
                <p>ОФИСА В СТРАНАТА </p>
              </div>
              <div className="col">
                <small>НАД</small>
                <h3>260 000</h3>
                <p>КЛИЕНТИ</p>
              </div>
              <div className="col">
                <small>&nbsp;</small>
                <h3>1</h3>
                <p>ПРИЛОЖЕНИЕ</p>
              </div>
            </div>
          </div>

        </div>
      </div>
    )
  }
}
export default Statistics