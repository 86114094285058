import React from "react";
import { TitleComponent, Mapforgoogle } from ".././../components";
import "./styles.scss";

const Section = ({
  renderContent,
  invert,
  title,
  image,
  specialImage,
  selected,
  reversed,
  removeLine,
  contain,
  mobile,
  moveupinner,
  blended,
  opacity,
  fullblended,
  height,
  map,
  fullwidth,
  X,
  Y,
  backgroundWhite,
  backgroundBlack,
  fullLine,
  titleMobile,
  fullInner,
  column,
  innerHeight,
  right,
  maxHeight,
  marginTop,
  unsetPos,
  first,
  innerML,
  paddingBtm,
  titleStyle
}) => {
  var color;
  if (reversed) {
    color = invert ? "black" : "white";
  } else {
    color = invert ? "white" : "black";
  }

  return (
    <div
      className={`section-container ${column && "column"} ${
        invert && "inverted"
      }  ${right && "right"} ${first && "first"}
    ${backgroundWhite && "backgroundWhite"}  ${mobile && "mobile"} ${
        opacity && "opacity"
      } ${fullblended && "fullblended"} `}
      style={{ height: `${height}vh`, marginTop: `${marginTop}%`,paddingBottom:`${paddingBtm}%` }}
    >
      {image === "" ? (
        ""
      ) : (
        <div
          className={`container  image ${
            contain && "contain"
          } `}
          style={{
            backgroundImage: image,
            backgroundPosition: unsetPos && `unset`,
          }}
        />
      )}
      <div
        className={`container ${first && "blue"} content ${color} ${
          moveupinner && "moveupinner"
        } ${backgroundBlack && "backgroundBlack"}
     ${blended && "blended"} ${opacity && "opacity"}
     ${backgroundWhite && "backgroundWhite"}`}
        style={{ maxHeight: maxHeight && `${maxHeight}%` }}
      >
        <div
          className={`container-inner ${moveupinner && "moveupinner"} ${
            fullInner && "fullInner"
          }`}
          style={{ height: innerHeight && `${innerHeight}%`, marginLeft:innerML && `${innerML}%` }}
        >
          {title === "" ? (
            ""
          ) : (
            <TitleComponent
              title={title}
              removeLine={removeLine}
              leftfullwidth={fullwidth}
              fullLine={fullLine}
              mobile={titleMobile}
              first={first}
              style={titleStyle}
            />
          )}
          {renderContent && renderContent()}
        </div>
      </div>
    </div>
  );
};

export default Section;
