import React, { Component } from 'react'
import { connect } from 'react-redux'
import AnimateHeight from 'react-animate-height';
import './TrustUsMobile.scss'

class TrustUsMobile extends Component {
    state = {
        height: {
            0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0
        },
    }

    toggle = (index) => {
        const { height } = this.state;
        height[index] = height[index] === 0 ? 'auto' : 0
        this.setState({
            height: height,
        });
    };

    render() {
        return (
            <div className="row boxes-wrap-m">

                <div className='col'>
                    <div id='trustus1' className={this.state.height[0] === 0 ? 'trust-m' : 'trust-m trust-open'}>
                        <div className="trust-box" onClick={() => this.toggle(0)}>
                            <div className='plus' ><img src='/icons/plus.svg' alt='-' /></div>
                            <div className='icon'><img className="trophy" src='/icons/trophy.svg' alt="" /></div>
                            <h4>ОТЛИЧНИ УСЛОВИЯ</h4>
                        </div>
                        <AnimateHeight className='trust-info' height={this.state.height[0]}>
                            <div className='minus' onClick={() => this.toggle(0)}><img src='/icons/minus.svg' alt='-' /></div>
                            <h4><img className="icon" src='/icons/trophy-white.svg' alt="" />ОТЛИЧНИ УСЛОВИЯ</h4>
                            <p>Сравняваме условията на всички застрахователни продукти на лицензирани застрахователни компании на едно място.</p>
                            <p>Намаляваме разходите на застраховане чрез анализ на риска на комбиниране на покрития</p>
                        </AnimateHeight>
                    </div>
                </div>
                <div className='col'>
                    <div id='trustus2' className={this.state.height[1] === 0 ? 'trust-m' : 'trust-m trust-open'}>
                        <div className="trust-box" onClick={() => this.toggle(1)}>
                            <div className='plus'><img src='/icons/plus.svg' alt='-' /></div>
                            <div className='icon'><img src='/icons/professional-doc.svg' alt="" /></div>
                            <h4>ПРОФЕСИОНАЛНИ КОНСУЛТАЦИИ</h4>
                        </div>
                        <AnimateHeight className='trust-info' height={this.state.height[1]}>
                            <div className='minus' onClick={() => this.toggle(1)}><img src='/icons/minus.svg' alt='-' /></div>
                            <h4><img className="icon" src='/icons/professional-doc-white.svg' alt="" />Професионални Консултации</h4>
                            <p>При нас винаги ще намерите професионална консултация базирана на вашите нужди.</p>
                            {/* <p>Стремим се чрез анализ на риска да намалим на разходите за застраховане и комбиниране на покрития</p> */}
                        </AnimateHeight>
                    </div>
                </div>
                <div className='col'>
                    <div id='trustus3' className={this.state.height[2] === 0 ? 'trust-m' : 'trust-m trust-open'}>
                        <div className="trust-box" onClick={() => this.toggle(2)}>
                            <div className='plus'><img src='/icons/plus.svg' alt='-' /></div>
                            <div className='icon'><img src='/icons/agreement.svg' alt="" /></div>
                            <h4>КОРЕКТНО ОБСЛУЖВАНЕ</h4>
                        </div>
                        <AnimateHeight className='trust-info' height={this.state.height[2]}>
                            <div className='minus' onClick={() => this.toggle(2)}><img src='/icons/minus.svg' alt='-' /></div>
                            <h4><img className="icon" src='/icons/agreement-white.svg' alt="" />Коректно Обслужване</h4>
                            <p>Вашият интерес е най-важен за нас. Ще ви предложим конкурентни условия и гъвкави тарифи</p>
                            <p>Можете да разчитате на методична помощ при завеждане на щети</p>
                        </AnimateHeight>
                    </div>
                </div>
                <div className='col'>
                    <div id='trustus4' className={this.state.height[3] === 0 ? 'trust-m' : 'trust-m trust-open'}>
                        <div className="trust-box" onClick={() => this.toggle(3)}>
                            <div className='plus'><img src='/icons/plus.svg' alt='-' /></div>
                            <div className='icon'><img src='/icons/bell.svg' alt="" /></div>
                            <h4>НАПОМНЯНЕ ЗА СРОКОВЕ</h4>
                        </div>
                        <AnimateHeight className='trust-info' height={this.state.height[3]}>
                            <div className='minus' onClick={() => this.toggle(3)}><img src='/icons/minus.svg' alt='-' /></div>
                            <h4><img className="icon" src='/icons/bell-white.svg' alt="" />Напомняне За срокове</h4>
                            <p>Ние ще ви уведомяваме за дължими вноски по разсрочена застрахователна­ премия по телефона или през нашето приложение.</p>
                            <p>Поддържане на досие при последващи промени в договора – анекси, добавъци и др.</p>
                        </AnimateHeight>
                    </div>
                </div>
            </div>

        )
    }
}


export default connect()(TrustUsMobile)