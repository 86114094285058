import React, { Component } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { QR } from "../../components";
import "./styles.scss";

const DownloadAppButton = () => {
  useEffect(() => {
  }, []);
  return (
    <div className="download-app noAbs">
      <NavLink
        to="/"
      >
        {" "}
      </NavLink>
      <div className="blue-container ">
        <div className="left-side">
          <div>
            <h1 style={{ textTransform: "uppercase", fontSize: "26px" }}>
              Линкът е достъпен през мобилен телефон.
            </h1>
            <p style={{ fontSize: "17px",marginBottom:"70px" }}>
              Моля отворете линка, използвайки Вашия мобилен телефон. Така ще
              можете да достъпите конкретната информация в мобилното приложение
              Амарант.
            </p>
          </div>
          <div className="download-section">
            <div className="left-download-section">
              <p style={{ fontSize: "17px" }}>Свалете от:</p>
              <div
                className="download-icon app-store"
                onClick={() => {
                  window.open(
                    "https://apps.apple.com/us/app/amarant-bulgaria/id1506724182"
                  );
                }}
              />
              <div
                className="download-icon google-play"
                onClick={() => {
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.amarantapp&hl"
                  );
                }}
              />
            </div>
            <div
              className="left-download-section"
              style={{ marginLeft: "15%", borderRight: "none" }}
            >
              <p style={{ fontSize: "17px" }}>Сканирайте кода:</p>
              <div className="download-icon qr" />
            </div>
          </div>
        </div>
        <div className="right-side-image" />
      </div>
      
    </div>
  );
};

export default connect()(DownloadAppButton);
