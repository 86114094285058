import React, { Component } from 'react';
import './styles.scss';

class Button extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      link: '',
      styleName: '',
      iconSrc: '',
      target: ''
    }
  }

  render() {
    const {title, link, styleName, iconSrc, target} = this.props
    return (
      <div className={styleName}>
        <a target={target ? target : '_self'} href={link} >
          {iconSrc ? <span className='icon'><img src={iconSrc} alt='' /></span> : null}
          <span className='title'>{title}</span>
        </a>
      </div>
    );
  }
}

export default Button;