import React, { Component } from 'react';
import './styles.scss';

class EuDoc extends Component {
  render() {
    return (
      <div className='amarant-eu'>
        <div className='row'>
          <div className='col col-1'>
            <div className='row row-logos'>
              <div className='col'><img src='/imgs/logo-eu.png' alt='Европейски Съюз, Еропейски Социален фонд' /></div>
              <div className='col'><img src='/imgs/logo-hr.png' alt='Развитие на човешките ресурси' /></div>
            </div>
          </div>
          <div className='col col-2'>
            <p><b>АМАРАНТ БЪЛГАРИЯ ООД изпълнява проект BG05M9OP001-1.057-0213-C01, който се осъществява с финансовата подкрепа на Оперативна програма „Развитие на човешките ресурс“ 2014-2020, съфинансирана от Европейския съюз чрез Европейския социален фонд</b></p>
            <p>Проектът е финансиран в рамките на процедура BG05M9OP001-1.057 УМЕНИЯ с административен договор № BG05M9OP001-1.057-0213-C01 „Подобряване достъпа до заетост и качеството на работните места, чрез повишаване квалификацията на заетите лица във фирма АМАРАНТ БЪЛГАРИЯ ООД“</p>
            <p><b>Начална дата на проекта:</b> 20.02.2020 г.</p>
            <p><b>Крайна дата на проекта:</b> 20.06.2021 г.</p>
            <p><b>Бюджет на проекта:</b> 937 500.00 лв. от които 562 500.00 лв. БФП и 375 000.00 лв. собствено финансиране.</p>
            <p><b>Кратко описание на проект BG05M9OP001-1.057-0213-C01 „Подобряване достъпа до заетост и качеството на работните места, чрез повишаване квалификацията на заетите лица във фирма АМАРАНТ БЪЛГАРИЯ ООД“, който се осъществява с финансовата подкрепа на Оперативна програма „Развитие на човешките ресурси“ 2014-2020, съфинансирана от Европейския съюз чрез Европейския социален фонд</b></p>
            <p><b>Основната цел на проекта</b> е да се повиши способността на заетите лица в „АМАРАНТ БЪЛГАРИЯ“ ООД да изградят умения за посрещане на динамичните темпове на пазара на труда, свързани с настъпващите бързи промени в резултат на глобализацията, актуалните нужди на бизнеса, технологичните и информационни промени, застаряване на населението и др. Проектът е насочен към предоставяне на обучения на заетите лица в дружеството за придобиване или повишаване на професионалната им квалификация, обучение по ключова компетентност и специфични обучения в сферата на застраховането. Обученията съответстват на необходимостта на работодателя, дейността на фирмата и актуалните нужди на бизнеса в сектора на предоставяне на застрахователни услуги.</p>
            <p><b>Основни дейности в проекта са:</b></p>
            <ul className='ul'>
              <li>Предоставяне на специфични бизнес обучения в сферата на застраховането;</li>
              <li>Предоставяне на обучение за повишаване на професионалната квалификация по професия "Икономика и мениджмънт" и по професия „Застрахователно и осигурително дело“;</li>
              <li>Предоставяне на обучение за придобиване на КК2 "Общуване на чужди езици".</li>
            </ul>
            <p><b>Резултати</b></p>
            <ul className='ul'>
              <li>повишена адекватност на трудовите умения на заетите, съобразени с актуалните нужди на бизнеса</li>
              <li>повишена производителност на труда</li>
              <li>създадени условия за по-качествени работни места и устойчива заетост</li>
            </ul>
            <p className='txt-right'><b>Качено на 21.02.2020г.</b></p>
          </div>
        </div>
      </div >
    );
  }
}

export default EuDoc;