import { blogTypes } from '../actions'
import { ofType, ActionsObservable } from 'redux-observable'
import { ajax } from 'rxjs/ajax'
import { switchMap, map, catchError } from 'rxjs/operators'
import { apiUrl } from '../config/settings'

export const getBlog = action$ =>
  action$.pipe(
    ofType(blogTypes.GET_BLOG),
    switchMap(({ payload }) => {
      return ajax({
        url: `${apiUrl}/blog`,
        method: 'GET',
        headers: '',
        body: JSON.stringify(payload)
      })
    .pipe(
      map(({ response }) => {
        console.log({response});
        if (response.success) {
          const payload = response.payload
          return ({ type: blogTypes.GET_BLOG_SUCCESS, payload })
        }
      }),
      catchError(err => {
        console.log('error', err)
        return ActionsObservable.create(obs => {
          obs.next({ type: blogTypes.GET_BLOG_FAIL, payload: {} })
          obs.complete()
        })
      })
    )
  })
)
export const getBlogPage = action$ =>
  action$.pipe(
    ofType(blogTypes.GET_BLOG_PAGE),
    switchMap(({ payload }) => {
      return ajax({
        url: `${apiUrl}/blog/${payload.page}`,
        method: 'GET',
        headers: '',
        body: JSON.stringify(payload)
      })
    .pipe(
      map(({ response }) => {
        if (response.success) {
          const payload = response.payload
          return ({ type: blogTypes.GET_BLOG_PAGE_SUCCESS, payload })
        }
      }),
      catchError(err => {
        console.log('error', err)
        return ActionsObservable.create(obs => {
          obs.next({ type: blogTypes.GET_BLOG_PAGE_FAIL, payload: {} })
          obs.complete()
        })
      })
    )
  })
)
export const getBlogNextPage = action$ =>
  action$.pipe(
    ofType(blogTypes.GET_BLOG_NEXT_PAGE),
    switchMap(({ payload }) => {
      return ajax({
        url: `${apiUrl}/blog/${payload.page}/${payload.size}`,
        method: 'GET',
        headers: '',
        body: JSON.stringify(payload)
      })
    .pipe(
      map(({ response }) => {
        if (response.success) {
          const payload = response.payload
          return ({ type: blogTypes.GET_BLOG_NEXT_PAGE_SUCCESS, payload })
        }
      }),
      catchError(err => {
        console.log('error', err)
        return ActionsObservable.create(obs => {
          obs.next({ type: blogTypes.GET_BLOG_NEXT_PAGE_FAIL, payload: {} })
          obs.complete()
        })
      })
    )
  })
)
export const getBlogPageWithSize = action$ =>
  action$.pipe(
    ofType(blogTypes.GET_BLOG_PAGE_WITH_SIZE),
    switchMap(({ payload }) => {
      return ajax({
        url: `${apiUrl}/blog/${payload.page}/${payload.size}`,
        method: 'GET',
        headers: '',
        body: JSON.stringify(payload)
      })
    .pipe(
      map(({ response }) => {
        console.log({response});
        if (response.success) {
          const payload = response.payload
          return ({ type: blogTypes.GET_BLOG_PAGE_WITH_SIZE_SUCCESS, payload })
        }
      }),
      catchError(err => {
        console.log('error', err)
        return ActionsObservable.create(obs => {
          obs.next({ type: blogTypes.GET_BLOG_PAGE_WITH_SIZE_FAIL, payload: {} })
          obs.complete()
        })
      })
    )
  })
)
export const getPost = action$ =>
  action$.pipe(
    ofType(blogTypes.GET_POST),
    switchMap(({ payload }) => {
      return ajax({
        url: `${apiUrl}/blog/post/${payload}`,
        method: 'GET',
        headers: '',
        body: JSON.stringify(payload)
      })
    .pipe(
      map(({ response }) => {
        console.log({response});
        if (response.success) {
          const payload = response.payload
          return ({ type: blogTypes.GET_POST_SUCCESS, payload })
        }
      }),
      catchError(err => {
        console.log('error', err)
        return ActionsObservable.create(obs => {
          obs.next({ type: blogTypes.GET_POST_FAIL, payload: {} })
          obs.complete()
        })
      })
    )
  })
)
