import React, { Component } from 'react'
import { Provider } from 'react-redux'
import { configureStore } from './config/stores'
import Routes from './components/routes/Routes'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './App.scss'

let store = configureStore()

class App extends Component {
  render () {
    return (
      <Provider store={store}>
        <Routes />
      </Provider>
    )
  }
}

export default App
