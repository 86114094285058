import React, { Component } from "react";
import { Link, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { getPost } from "../../actions";
import moment from "moment";
import parse from "html-react-parser";
import "./styles.scss";
import { useEffect } from "react";
import { TitleComponent } from "../../components";

const Article = ({ getPost, post }) => {
  const location = useLocation();
  const postID = location.pathname.split("/")[2];

  useEffect(() => {
    getPost(postID);
  }, []);

  console.log(postID === "601d43db5fa198001525863d");

  const text = String(post.text);

  return (
    <>
      {window.innerWidth < 900 ? (
        <>
          <div className="mobile-article-container">
            <div
              className="mobile-right-article-container"
              style={{ backgroundImage: post?.title?.includes("Във връзка с отнемане") || post?.title?.includes("Информация от Румънския")  ? `url(${require("../../assets/imgs/111.PNG")})` : `url(${post.coverPhoto})` }}
            />
            <Link to="/blog" >
              <div className="arrow-left mobile" style={{marginBottom:"5%"}} />
            </Link>

            
            <h3 style={{ marginLeft: "2%", marginBottom: "5%" }}>
              {post.title}
            </h3>
            <div
              className="mobile-neshto"
              style={{ paddingBottom: "50px", marginLeft: "3%" }}
            >
              {parse(text)}
            </div>
          </div>
        </>
      ) : (
        <div className="article-container">
          <div className="left-article-container">
            <Link to="/blog">
              <div className="arrow-left" />
            </Link>
           
            <h1 className="article-title" style={{marginTop:"4%"}}>{post.title}</h1>
            <div className="neshto">
              <div className="article-text">{parse(text)}</div>
            </div>
          </div>
          <div
            className="right-article-container"
            style={{
              backgroundImage: `url(${post.coverPhoto})`,
            }}
          ></div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  post: state.blog.post,
});
const mapDispachToProps = (dispatch) => ({
  getPost: (payload) => dispatch(getPost(payload)),
});
export default connect(mapStateToProps, mapDispachToProps)(Article);
