import React, { Component } from 'react'
import { connect } from 'react-redux'
import AnimateHeight from 'react-animate-height';

class FaqsListInfo9 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            height: {
                0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0,
            },

        }
    }

    toggle = (index) => {
        const { height } = this.state;
        height[index] = height[index] === 0 ? 'auto' : 0
        this.setState({
            height: height
        });
    };

    render() {
        // const { height } = this.state;

        return (
            <div className='faqs-list-info'>
                <div className='faq-info'>
                    <div className={this.state.height[0] === 0 ? 'open' : 'close'} onClick={() => this.toggle(0)}>
                        <div>Завеждане на щета</div>
                    </div>
                    <AnimateHeight height={this.state.height[0]} >
                        <div>
                            <p>През мобилното приложение на Амарант ще можете да се възползвате от възможността за “Завеждане на щета” навсякъде, по всяко време и само с няколко клика. </p>
                        </div>
                    </AnimateHeight>
                </div>

                <div className='faq-info'>
                    <div className={this.state.height[1] === 0 ? 'open' : 'close'} onClick={() => this.toggle(1)}>
                        <div>Годишен Технически Преглед</div>
                    </div>
                    <AnimateHeight height={this.state.height[1]} >
                        <div>
                            <p>Можете да запазите час за Годишен Технически Преглед, избирайки най-подходящите за Вас местоположение, дата и точен час.</p>
                        </div>
                    </AnimateHeight>
                </div>
                <div className='faq-info'>
                    <div className={this.state.height[2] === 0 ? 'open' : 'close'} onClick={() => this.toggle(2)}>
                        <div>Синя / Зелена зона</div>
                    </div>
                    <AnimateHeight height={this.state.height[2]} >
                        <div>
                            <p>Мобилното приложение на Амарант ще предоставя възможност за заплащане на синя и зелена зона в цяла България.</p>
                        </div>
                    </AnimateHeight>
                </div>
            </div>
        )
    }

}

export default connect()(FaqsListInfo9)