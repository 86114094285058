import React, { Component } from 'react';
import { AppsDownload } from '../../components'
import './styles.scss';

class BannerApp extends Component {
  render() {
    return (
      <div className='section section-banner-app'>
        <div className='container'>
          <div className='title'>Свалете нашето приложение</div>
          <div className='phoneapp'><img src='/imgs/phoneapp-mockup.svg' alt="Amarant App" /></div>
          <div className='apps'><AppsDownload /></div>
        </div>
      </div>
    );
  }
}

export default BannerApp;