import React, { Component } from 'react';
import HamburgerMenu from 'react-hamburger-menu';
import { NavMainMobileItems } from '../../components'
import './mstyles.scss'

class NavMainMobile extends Component {

  constructor(props) {
    super(props);

    this.state = {

      isOpen: false,

    };

  }

  closeMenu() {
    this.setState({ isOpen: false })
  }
  toggleMenu() {
    this.setState({ isOpen: !this.state.isOpen });

  }


  render() {
    const toggleNavIsOpen = this.state.isOpen;
    let toggleNavStyles;

    if (toggleNavIsOpen) {
      toggleNavStyles = 'nav-main-toggle isOpen';
      document.body.style.overflow = 'hidden';
    } else {
      toggleNavStyles = 'nav-main-toggle';
      document.body.style.overflow = 'auto';
    }

    return (
      <div className='nav-main-mobile-wrap'>
      
        <div className="burger-icon">
          <HamburgerMenu
            isOpen={this.state.isOpen}
            menuClicked={this.toggleMenu.bind(this)}
            width={32}
            height={20}
            strokeWidth={3}
            rotate={0}
            color='white'
            borderRadius={0}
            animationDuration={0.5}
          />

        </div>


        <div onClick={() => this.closeMenu()} className={toggleNavStyles}  >

          <NavMainMobileItems

          />
        </div>
      </div>
    );
  }
}

export default NavMainMobile;
