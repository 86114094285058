import React, { Component } from 'react'
import { connect } from 'react-redux'
import './TrustUs.scss'

class TrustUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            trustOpen: [
                { open: false },
                { open: false },
                { open: false },
                { open: false }
            ]
        }
    }

    componentDidMount() {
        this.setState({
            trustOpen: this.state.trustOpen,
        })
      }

    toggle = (id) => {
        // const { trustOpen } = this.state;

        let trustBoxes = document.getElementsByClassName('trust');
        let newArray = [...this.state.trustOpen];

        for (let i = 0; i < trustBoxes.length; i++) {
            if (trustBoxes[i].id === id) {
                newArray[i].open = !newArray[i].open;
                if (newArray[i].open === true) {
                    trustBoxes[i].className = 'trust trust-open';
                    trustBoxes[i].parentElement.style.zIndex = 2;
                } else {
                    trustBoxes[i].className = 'trust'
                }
            } else {
                newArray[i].open = false;
                if (newArray[i].open === true) {
                    trustBoxes[i].className = 'trust trust-open';
                    trustBoxes[i].parentElement.style.zIndex = 2;
                } else {
                    trustBoxes[i].className = 'trust';
                }
            }

            setTimeout(function () {
                if (trustBoxes[i].className === 'trust') {
                    trustBoxes[i].parentElement.style.zIndex = 1;
                }
            }, 700);

        }
        this.setState({ trustOpen: newArray })
    };

    render() {

        return (

            <div className="row boxes-wrap">

                <div className='col'>
                    <div id='trustus1' className='trust'>
                        <div className='plus' onClick={() => this.toggle('trustus1')}><img src='/icons/plus.svg' alt='-' /></div>
                        <div className='trust-info'>
                            <div className='minus' onClick={() => this.toggle('trustus1')}><img src='/icons/minus.svg' alt='-' /></div>
                            <h4><img className="icon" src='/icons/trophy-white.svg' alt="" />ОТЛИЧНИ УСЛОВИЯ</h4>
                            <p>Сравняваме условията на всички застрахователни продукти на лицензирани застрахователни компании на едно място.</p>
                            <p>Намаляваме разходите на застраховане чрез анализ на риска на комбиниране на покрития</p>
                        </div>
                        <div className="trust-box">
                            <div className='icon'><img className="trophy" src='/icons/trophy.svg' alt="" /></div>
                            <h4>ОТЛИЧНИ УСЛОВИЯ</h4>
                        </div>
                    </div>
                </div>
                <div className='col'>
                    <div id='trustus2' className='trust'>
                        <div className='plus' onClick={() => this.toggle('trustus2')}><img src='/icons/plus.svg' alt='-' /></div>
                        <div className='trust-info'>
                            <div className='minus' onClick={() => this.toggle('trustus2')}><img src='/icons/minus.svg' alt='-' /></div>
                            <h4><img className="icon" src='/icons/professional-doc-white.svg' alt="" />Професионални Консултации</h4>
                            <p>При нас винаги ще намерите професионална консултация базирана на вашите нужди.</p>
                            {/* <p>Стремим се чрез анализ на риска да намалим на разходите за застраховане и комбиниране на покрития</p> */}
                        </div>
                        <div className="trust-box">
                            <div className='icon'><img src='/icons/professional-doc.svg' alt="" /></div>
                            <h4>ПРОФЕСИОНАЛНИ КОНСУЛТАЦИИ</h4>
                        </div>
                    </div>
                </div>
                <div className='col'>
                    <div id='trustus3' className='trust'>
                        <div className='plus' onClick={() => this.toggle('trustus3')}><img src='/icons/plus.svg' alt='-' /></div>
                        <div className='trust-info'>
                            <div className='minus' onClick={() => this.toggle('trustus3')}><img src='/icons/minus.svg' alt='-' /></div>
                            <h4><img className="icon" src='/icons/agreement-white.svg' alt="" />Коректно Обслужване</h4>
                            <p>Вашият интерес е най-важен за нас. Ще ви предложим конкурентни условия и гъвкави тарифи</p>
                            <p>Можете да разчитате на методична помощ при завеждане на щети</p>
                        </div>
                        <div className="trust-box">
                            <div className='icon'><img src='/icons/agreement.svg' alt="" /></div>
                            <h4>КОРЕКТНО ОБСЛУЖВАНЕ</h4>
                        </div>
                    </div>
                </div>
                <div className='col'>
                    <div id='trustus4' className='trust'>
                        <div className='plus' onClick={() => this.toggle('trustus4')}><img src='/icons/plus.svg' alt='-' /></div>
                        <div className='trust-info'>
                            <div className='minus' onClick={() => this.toggle('trustus4')}><img src='/icons/minus.svg' alt='-' /></div>
                            <h4><img className="icon" src='/icons/bell-white.svg' alt="" />Напомняне За срокове</h4>
                            <p>Ние ще ви уведомяваме за дължими вноски по разсрочена застрахователна­ премия по телефона или през нашето приложение.</p>
                            <p>Поддържане на досие при последващи промени в договора – анекси, добавъци и др.</p>
                        </div>
                        <div className="trust-box">
                            <div className='icon'><img src='/icons/bell.svg' alt="" /></div>
                            <h4>НАПОМНЯНЕ ЗА СРОКОВЕ</h4>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}


export default connect()(TrustUs)