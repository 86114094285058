import React from "react";
import { useEffect, useState } from "react";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import { Link } from "react-router-dom";
import "./styles.scss";

export default function NewsComponent({
  blogPageWithSize,
  getBlogPageWithSize,
  nextPage,
}) {
  const callbackFunction = () => {
    getBlogPageWithSize({
      page: nextPage,
      size: 10,
    });
  };
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  useBottomScrollListener(callbackFunction);

  return (
    <div className="news-container">
      {blogPageWithSize.map((blog, i) => {
        return (
          <div
            className="vertical-news"
            style={{
              backgroundImage: `url(${blog.coverPhoto})`,
            }}
            key={i}
          >
            <div className={`upper-vertical-news ${i % 2 === 0 && "blue"}`}>
              <h4
                className={`title-news ${i % 2 === 1 && "blue"}`}
                style={{ textTransform: "uppercase" }}
              >
                {blog.title}
              </h4>
              <Link to={`/article/${blog._id}`} className={`read-more ${i % 2 === 1 && "blue"}`} style={{opacity: window.innerWidth < 900 && "1"}}>
                <div style={{display:"flex"}}>Прочети повече <div className={`arrowTab ${i % 2 === 1 ? "selectedBlue" : "selected"}`} style={{height:"31px",marginLeft:window.innerWidth > 900 ? "8%" : "4%",marginTop: window.innerWidth > 900 ? "0.3%" : window.innerWidth < 900 && window.innerWidth > 760 ? "-0.4%" : "0%"}}></div></div>
              </Link>
            </div>
            {/* <div className="text-container news">
              <div className="inner-container">
                <h4
                  className="title-news"
                  style={{ textTransform: "uppercase" }}
                >
                  {blog.title}
                </h4>
                <div className="line-news-title" />
                <div className="read-article">
                  <Link to={`/article/${blog._id}`}>
                  <div className="read-more">Прочети повече</div>
                  </Link>
                </div>
              </div>
            </div> */}
          </div>
        );
      })}
    </div>
  );
}
