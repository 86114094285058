import React, { Component,useRef,useState } from "react";
import Slider from "react-slick";
import "./styles.scss";

const textMap = [
  {
    name: "Ивайло Милованов",
    text: "Подробна информация за ГО, ГТП, валидност на винетка, връчени и невръчени актове за нарушения - лесно и бързо прави проверка! Препоръвам ! 🙂👍🏻",
  },
  {
    name: "Rosen Mechenski",
    text: "Много удобно приложение, всичко е на едно място: глоби, ГТП, гражданска отговорност, винетка. Особено полезно е за собственици на повече превозни средства.",
  },
  {
    name: "Dimitar Tsonov",
    text: "Много полезно и редовно обновявано приложение. Браво на създателите, дано продължат да го поддържат и развиват на такова топ ниво :)",
  },
  {
    name: "petrotro",
    text: "Изключително полезно и лесно за използване. Много необходимо за тези, които имат повече от едно превозно средство. Горещо препоръчвам!",
  },
  {
    name: "Vasil Peev",
    text: "Приложението работи много добре. Доволен съм, че най-после може да се направи проверка за задължения към КАТ, дори да не притежавате българска шофьорска книжка. Поздравления на екипа!",
  },
  {
    name: "Maya__sss",
    text: "Много удобно, особено като за нов шофьор, който няма навик да проверява документи за колата му. Топ е!",
  },
  {
    name: "Angel T",
    text: "Показва цялата информация с точност. Процесът на въвеждане на данните е много улеснен. Отлично приложение.",
  },
  {
    name: "Monkich",
    text: "Изключително добре създадено приложение! Аз лично съм много доволна! Поздравявам целия екип, който е създал и поддържа приложението! Адмирации!",
  },
  {
    name: "Mario Hristev",
    text: "Много хубав апп, благодарение на него нямам глоба за изтекла шофьорска книжка. Топ сте, продължавайте да си развивате аппа. Топ услуга, препоръчвам!",
  },
  {
    name: "Rumi1808",
    text: "Кратко, точно и ясно! Пълно с всички неща, които трябват. Много полезно и удобно! Препоръчвам горещо!",
  },

];

const ReviewComponent = () => {

  const [activeSlide,setActiveSlide] = useState(0)

  const slider = useRef();

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    centerPadding: "60px",
    arrows: false,
    slidesToScroll: 3,
    swipeToSlide: true,
    beforeChange: (current, next) => {setActiveSlide(next)},
  };

  console.log(activeSlide)
  
  return (
    <div
      style={{
        height: window.innerWidth < 900 ? "unset" : "100vh",
        width: "99vw",
        alignItems: "center",
        flexDirection: "column",
        display: "flex",
      }}
    >
      <h1
        className={`line`}
        style={{
          textAlign: "center",
          marginTop: window.innerWidth < 900 ? "10%" : "2%",
          color: "black",
          marginBottom:window.innerWidth < 900 && window.innerWidth > 760 ? "7%" : window.innerWidth < 900 && window.innerWidth < 760  && "15%",
          fontSize:window.innerWidth < 900 && "25px"
        }}
      >
        {"РЕВЮТА НА НАШИ КЛИЕНТИ"}
      </h1>
      <div className="review-container">
        <div className="arrows">
          <div className={`arrow left ${activeSlide === 0 && "inactive"}`} onClick={() => {
            slider.current.slickPrev()
          }}/>
          <div className={`arrow ${activeSlide === 9  && "inactive"}`} onClick={() => {
            slider.current.slickNext()
          }} />
        </div>
        <div>
          {window.innerWidth < 900 ?
          <>
             {textMap.map((text, i) => {
            return (
              <div className="review-card" style={{marginLeft:"3%",marginBottom:"5%",height:"unset",padding:"3%"}}>
                <div className="header-card">
                  <div
                    className={`profileImage ${
                     i % 2 === 0 ? "google" : "apple"
                    }`}
                  />
                  <div className="name-rating">
                    <h4 className="name" style={{fontSize:window.innerWidth < 900 && "16px"}}>{text.name}</h4>
                    <div className="rating" />
                  </div>
                </div>
                <p className="review-text">{text.text}</p>
              </div>
            );
          })}
          </> :
        <Slider ref={slider} {...settings} style={{width:"97vw",height:"25vh"}}>
          {textMap.map((text, i) => {
            return (
              <div className="review-card">
                <div className="header-card">
                  <div
                    className={`profileImage ${
                     i % 2 === 0 ? "google" : "apple"
                    }`}
                  />
                  <div className="name-rating">
                    <h4 className="name" style={{fontSize:window.innerWidth < 900 && "16px"}}>{text.name}</h4>
                    <div className="rating" />
                  </div>
                </div>
                <p className="review-text">{text.text}</p>
              </div>
            );
          })}
        </Slider>
        }
        </div>
      </div>
      <div className="info-container">
        <div className="left-info-container">
          <div style={{marginLeft:window.innerWidth < 900 && "-2%"}}>
            <p className="infoText" style={{ fontSize: "27px", textAlign: "center",paddingBottom:"0px",textShadow:"2px 2px #00000080",marginBottom:"25px" }}>НАД <b style={{fontSize:"48px"}}>500.000</b> ТЕГЛЕНИЯ</p>
            <div className="line-review"/>
            <p className="infoText" style={{ fontSize: "27px", textAlign: "center",paddingBottom:"0px",textShadow:"2px 2px #00000080",marginTop:"20px" }}>ЗА ПО-МАЛКО ОТ <b style={{fontSize:"48px"}}>2</b> ГОДИНИ</p>
          </div>
        </div>
        <div className="right-info-container">
          <div style={{marginLeft:window.innerWidth < 900 && "-2%"}}>
          <p className="infoText" style={{ fontSize: "27px", textAlign: "center",paddingBottom:"0px",textShadow:"2px 2px #00000080",marginBottom:"25px" }}>СРЕДНА ОЦЕНКА <b style={{fontSize:"48px"}}>4.9</b> ОТ <b style={{fontSize:"48px"}}>5</b></p>
            <div className="line-review"/>
            <p className="infoText" style={{ fontSize: "27px", textAlign: "center",paddingBottom:"0px",textShadow:"2px 2px #00000080",marginTop: "30px" }}>НА БАЗА ОТ  <b style={{fontSize:"48px"}}>15.000</b> РЕВЮТА</p>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewComponent;
