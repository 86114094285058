import React, { Component } from "react";
import "./styles.scss";

const textMap = [
  {
    title: "КАКВО Е БЪРЗА ПРОВЕРКА?",
    text: "Автоматично извличане на цялата информация за Вашия автомобил чрез сигурна връзка с държавните агенции",
  },
  
  {
    title: "ЗА КАКВО СЛУЖИ?",
    text: "Получавате информация за валидността на Гражданска отговорност, технически преглед, винетка, както и за налични глоби към КАТ",
  },
];

const secontTextMap = [
  {
    title: "БЕЗПЛАТНО ЛИ Е?",
    text: "Да - правете неограничен брой справки за колкото автомобили пожелаете",
  },
  {
    title: "КАК РАБОТИ?",
    text: "Просто сканирайте талона и всичко ще се случи автоматично.",
  },
];

const MobileFastCheck = ({ actions }) => {
  return (
    <div className={`mobile-fast-check ${actions && "actions"}`} style={{height:!actions && window.innerWidth < 900 && window.innerWidth > 760 && "155vh"}}>
      <h1
        style={{
          color: actions ? "black" : "white",
          marginBottom: window.innerWidth < 900 && "5%",
          paddingBottom: "0px",
          fontSize: "28px",
          marginTop: "5%",
        }}
      >
        БЪРЗА ПРОВЕРКА
      </h1>
      <div className={`mobile-fast-check-img ${actions && "actions"}`} style={{height: window.innerWidth < 900 && window.innerWidth > 760 && "38%"}} />
      {actions ? (
        <>
          <div className="tab" style={{marginTop:window.innerWidth < 900 && window.innerWidth > 760 ? "4%" : "7%"}}>
            <div className="tabImage first" />
            <h3
              style={{
                textTransform: "uppercase",
                fontSize: "16px",
                paddingBottom: "0px",
              }}
            >
              закупуване на гражданска отговорност
            </h3>
          </div>
          <div className="tab" style={{marginTop:window.innerWidth < 900 && window.innerWidth > 760 ? "3%" : "7%"}}>
            <div className="tabImage second" />
            <h3
              style={{
                textTransform: "uppercase",
                fontSize: "16px",
                paddingBottom: "0px",
              }}
            >
              ЗАКУПУВАНЕ НА ДРУГИ ЗАСТРАХОВки
            </h3>
          </div>
          <div className="tab" style={{marginTop:window.innerWidth < 900 && window.innerWidth > 760 ? "3%" : "7%"}}>
            <div className="tabImage third" />
            <h3
              style={{
                textTransform: "uppercase",
                fontSize: "16px",
                paddingBottom: "0px",
              }}
            >
              Опция за плащане на вноски по полица
            </h3>
          </div>
          <div className="tab" style={{marginTop:window.innerWidth < 900 && window.innerWidth > 760 ? "3%" : "7%"}}>
            <div className="tabImage fourth" />
            <h3
              style={{
                textTransform: "uppercase",
                fontSize: "16px",
                paddingBottom: "0px",
              }}
            >
              подновяване на изтичаща полица
            </h3>
          </div>
          <div className="tab" style={{marginTop:window.innerWidth < 900 && window.innerWidth > 760 ? "3%" : "7%"}}>
            <div className="tabImage fifth" />
            <h3
              style={{
                textTransform: "uppercase",
                fontSize: "17px",
                paddingBottom: "0px",
              }}
            >
              добавяне на съществуваща полица
            </h3>
          </div>
          <div className="tab" style={{marginTop:window.innerWidth < 900 && window.innerWidth > 760 ? "3%" : "7%"}}>
            <div className="tabImage sixth" />
            <h3
              style={{
                textTransform: "uppercase",
                fontSize: "17px",
                paddingBottom: "0px",
              }}
            >
              Напомняне при изтичане на полица
            </h3>
          </div>
          <div className="tab" style={{marginTop:window.innerWidth < 900 && window.innerWidth > 760 ? "3%" : "7%"}}>
            <div className="tabImage seventh" />
            <h3
              style={{
                textTransform: "uppercase",
                fontSize: "17px",
                paddingBottom: "0px",
              }}
            >
              закупуване на винетка
            </h3>
          </div>
          <div className="tab" style={{marginTop:window.innerWidth < 900 && window.innerWidth > 760 ? "3%" : "7%"}}>
            <div className="tabImage eighth" />
            <h3
              style={{
                textTransform: "uppercase",
                fontSize: "17px",
                paddingBottom: "0px",
              }}
            >
              заплащане на връчени глоби към кат
            </h3>
          </div>
        </>
      ) : (
        <>
          {textMap.map((text, i) => {
            return (
              <div
                className="card"
                style={{ width: "90%", marginTop: i > 0 ? "5%" : "4%",padding:"3%",height:window.innerWidth < 900 && window.innerWidth > 760 ? "unset" : "170px" }}
              >
                <div className="first">
                  <div className={`card-icon ${i === 0 ? "first" : "third"}`} />
                  <h3 className="card-title">{text.title}</h3>
                </div>
                <p>{text.text}</p>
              </div>
            );
          })}
          {secontTextMap.map((text, i) => {
            return (
              <div
                className="card"
                style={{
                  width: "90%",
                  marginTop: "5%",
                  marginBottom: i === 1 && "15px",
                  padding:"3%"
                  ,height:window.innerWidth < 900 && window.innerWidth > 760 ? "unset" : "170px"
                }}
              >
                <div className="first">
                  <div
                    className={`card-icon ${i === 0 ? "second" : "forth"}`}
                  />
                  <h3 className="card-title">{text.title}</h3>
                </div>
                <p>{text.text}</p>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};

export default MobileFastCheck;
