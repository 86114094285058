import { combineReducers } from 'redux'
// import { createNavigationReducer } from 'react-navigation-redux-helpers'
import { routerReducer } from 'react-router-redux'

import starter from './starter'
import blog from './blog'
import offices from './offices'


export default combineReducers({
  starter,
  blog,
  offices,
  routerReducer
})
