import React, { useEffect, useState } from "react";
import { Section, TitleComponent } from "../../components";
import FastCheck from "../../components/FastCheck/FastCheck";
import MobileFastCheck from "../../components/FastCheck/MobileFastCheck";
import ReviewComponent from "../../components/ReviewComponent/ReviewComponent";
import "./styles.scss";

const whoIsAmarant = {
  1: {
    number: 20,
    text: "години опит",
  },
  2: {
    image: "",
    number: 230,
    text: "офиса в страната",
  },
  3: {
    number: 260,
    text: "клиенти",
  },
  4: {
    number: 1,
    text: "мобилно приложение",
  },
};

const Home = () => {
  const [selected, setSelected] = useState(0);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <Section
        image={`url(${require("../../assets/imgs/homepage-section-1.png")})`}
        first
        reversed
        invert
        height={window.innerWidth < 900 ? 125 : 100}
        title={"Всичко за автомобила по дигитален път"}
        removeLine
        maxHeight={window.innerWidth < 900 && 40}
        renderContent={() => {
          return (
            <>
              <p className="info-text">
                Най-доброто мобилно приложение за цялостно управление на Вашия
                автомобил - застраховки, винетки, технически прегледи,
                паркиране, заснемане нарушения на пътя, следене на разходи,
                завеждане на щети.
              </p>
              <p className="info-text" style={{ marginTop: "4%" }}>
                Свалете от:
              </p>
              <div className="dw-btn-container">
                <div
                  className="dw-button app-store"
                  onClick={() => {
                    window.open(
                      "https://apps.apple.com/us/app/amarant-bulgaria/id1506724182"
                    );
                  }}
                />
                <div
                  className="dw-button google"
                  onClick={() => {
                    window.open(
                      "https://play.google.com/store/apps/details?id=com.amarantapp&hl"
                    );
                  }}
                />
              </div>
              <p
                className="info-text"
                style={{
                  marginTop: "3%",
                  display: window.innerWidth < 900 && "none",
                }}
              >
                Или сканирайте кода:
              </p>
              <div
                className="dw-btn-container"
                style={{ display: window.innerWidth < 900 && "none" }}
              >
                <div className="dw-button qr" />
              </div>
            </>
          );
        }}
      />

      <Section
        image={`url(${require("../../assets/imgs/homepage-section-2.png")})`}
        right={window.innerWidth > 900 && true}
        reversed
        height={
          window.innerWidth < 900 && window.innerWidth < 760
            ? 130
            : window.innerWidth < 900 && window.innerWidth > 760
            ? 160
            : window.innerWidth > 900 && 100
        }
        paddingBtm={window.innerWidth < 900 && 4}
        title={"кои са амарант"}
        titleMobile={true}
        renderContent={() => {
          return (
            <>
              <p className="info-text">
                Водещ застрахователен брокер в България с над 20 години
                опит и стотици хиляди клиенти.
              </p>
              <p className="info-text">
                Новата стъпка в развитието е цялостна дигитализация. Затова
                създадохме първото по рода си мобилно приложение, което е не
                само за застраховки, но обединява всичко необходимо за един
                автомобил на едно място.
              </p>
              <div className="dotted-btns-container">
                {Object.keys(whoIsAmarant).map((key, i) => {
                  return (
                    <div className="dotted-btns">
                      <div
                        className="btn-image"
                        style={{
                          backgroundImage: `url(${require(i === 0
                            ? "../../assets/icons/12.png"
                            : i === 1
                            ? "../../assets/icons/homepage-section-2-offices.png"
                            : i === 2
                            ? "../../assets/icons/homepage-section-2-clients.png"
                            : "../../assets/icons/homepage-section-2-mobile-app.png")})`,
                        }}
                      />
                      <h3
                        style={{
                          paddingBottom: "0px",
                          fontSize: window.innerWidth < 900 && "16px",
                          textTransform: "uppercase",
                        }}
                      >
                        {i > 0 && i < 3 ? "Над " : ""}
                        <b style={{ color: "#1F55E6", fontSize: "29px" }}>
                          {whoIsAmarant[key].number === 260
                            ? `${whoIsAmarant[key].number}.000`
                            : whoIsAmarant[key].number}
                        </b>{" "}
                        {whoIsAmarant[key].text}
                      </h3>
                    </div>
                  );
                })}
              </div>
            </>
          );
        }}
      />

      {window.innerWidth < 900 ? <MobileFastCheck /> : <FastCheck />}

      <div className="reference-container">
        <div className="inner-ref-cont">
          <div className="info-cont">
            <TitleComponent title={"СПРАВКИ"} style={{ marginTop: "40px" }} />
            <p style={{ marginTop: "-3%", fontSize: "17px" }}>
              След направената бърза проверка ще видите автомобила си и
              цялата информация за него, като имате възможност да добавите и други
              автомобили. От менюто можете да проверите наличието и давността
              на:
            </p>
            <div
              className="whiteLine"
              style={{
                backgroundColor: "unset",
                color: "white",
                border: "2px dotted",
                borderColor: "#EAEDFE",
              }}
            >
              <div
                className="lineImage first"
                style={{
                  height: "107.5%",
                  borderRadius: "5px",
                  marginLeft: "-1%",
                  width: "60px",
                  backgroundColor: "#EAEDFE",
                  backgroundPosition: "center",
                }}
              />
              <h3
                style={{
                  fontSize: "17px",
                  paddingBottom: "0px",
                  marginLeft: "5%",
                  textTransform: "uppercase",
                }}
              >
                Гражданска отговорност
              </h3>
            </div>
            <div
              className="whiteLine"
              style={{
                backgroundColor: "unset",
                color: "white",
                border: "2px dotted",
                borderColor: "#EAEDFE",
              }}
            >
              <div
                className="lineImage third"
                style={{
                  height: "107.5%",
                  borderRadius: "5px",
                  marginLeft: "-1%",
                  width: "60px",
                  backgroundColor: "#EAEDFE",
                  backgroundPosition: "center",
                }}
              />
              <h3
                style={{
                  fontSize: "17px",
                  paddingBottom: "0px",
                  marginLeft: "5%",
                  textTransform: "uppercase",
                }}
              >
                Винетка
              </h3>
            </div>
            <div
              className="whiteLine"
              style={{
                backgroundColor: "unset",
                color: "white",
                border: "2px dotted",
                borderColor: "#EAEDFE",
              }}
            >
              <div
                className="lineImage fourth"
                style={{
                  height: "107.5%",
                  borderRadius: "5px",
                  marginLeft: "-1%",
                  width: "60px",
                  backgroundColor: "#EAEDFE",
                  backgroundPosition: "center",
                }}
              />
              <h3
                style={{
                  fontSize: "17px",
                  paddingBottom: "0px",
                  marginLeft: "5%",
                  textTransform: "uppercase",
                }}
              >
                Годишен технически преглед
              </h3>
            </div>
            <div
              className="whiteLine"
              style={{
                backgroundColor: "unset",
                color: "white",
                border: "2px dotted",
                borderColor: "#EAEDFE",
              }}
            >
              <div
                className="lineImage fifth"
                style={{
                  height: "107.5%",
                  borderRadius: "5px",
                  marginLeft: "-1%",
                  width: "60px",
                  backgroundColor: "#EAEDFE",
                  backgroundPosition: "center",
                }}
              />
              <h3
                style={{
                  fontSize: "17px",
                  paddingBottom: "0px",
                  marginLeft: "5%",
                  textTransform: "uppercase",
                }}
              >
                Глоби към КАТ
              </h3>
            </div>
          </div>
          <div className="ref-image" />
        </div>
      </div>

      {window.innerWidth < 900 ? (
        <MobileFastCheck actions />
      ) : (
        <FastCheck actions />
      )}

      {window.innerWidth < 900 ? (
        <>
          <TitleComponent
            title={"услуги"}
            marginleft={5}
            style={{ marginTop: "35px" }}
          />
          <Section
            image={`url(${require("../../assets/imgs/homepage-section-6-phone-1.png")})`}
            right={window.innerWidth > 900 && true}
            reversed
            title={""}
            height={window.innerWidth > 750 ? 130 : 100}
            maxHeight={
              window.innerWidth > 750 && window.innerWidth < 900 ? 30 : 40
            }
            renderContent={() => {
              return (
                <div className="overlay-container">
                  <div className="wrapper">
                    <div
                      className={`flex-container ${
                        selected === 0 && "selected"
                      }`}
                      onClick={() => setSelected(0)}
                    >
                      <div
                        className={`line-and-text ${
                          selected === 0 && "selected"
                        }`}
                      >
                        <div
                          className={`left-line first ${
                            selected === 0 && "selected"
                          }`}
                        />
                        <h7
                          className={`tab-title ${
                            selected === 0 && "selected"
                          }`}
                        >
                          ПАРКИРАНЕ В ЗОНА
                        </h7>
                      </div>
                    </div>
                    <p className={`text-info ${selected === 0 && "selected"}`}>
                      Заплащайте синя и зелена зона директно през Амарант.
                      Приложението автоматично Ви локализира и показва
                      релевантната информация - работно време, цена и краткия
                      телефон за изпращане на смс. Натиснете бутона “плати” и
                      автоматично ще се добави този разход в общата справка за
                      автомобила.
                    </p>
                  </div>
                </div>
              );
            }}
          />
          <Section
            image={`url(${require("../../assets/imgs/homepage-section-6-phone-2.png")})`}
            right={window.innerWidth > 900 && true}
            reversed
            title={""}
            height={window.innerWidth > 750 ? 130 : 100}
            maxHeight={window.innerWidth > 750 ? 30 : 60}
            renderContent={() => {
              return (
                <div className="overlay-container">
                  <div className="wrapper">
                    <div
                      className={`flex-container selected`}
                      onClick={() => setSelected(1)}
                    >
                      <div className={`line-and-text selected`}>
                        <div className={`left-line second selected`} />
                        <h7 className={`tab-title selected`}>
                          ВИДЕОРЕГИСТРАТОР
                        </h7>
                      </div>
                    </div>
                    <p className={`text-info selected`}>
                      Телефонът Ви се превръща във видеорегистратор благодарение
                      на Амарант и уникалната технология, използвана в
                      приложението. С един клик записвате това, което току-що се
                      е случило. Всичко останало автоматично се изтрива. Така
                      имате само най-важното като запис - за споделяне в
                      социалните мрежи или директно към КАТ.
                    </p>
                  </div>
                </div>
              );
            }}
          />
          <Section
            image={`url(${require("../../assets/imgs/homepage-section-6-phone-3.png")})`}
            right={window.innerWidth > 900 && true}
            reversed
            title={""}
            height={window.innerWidth > 750 ? 130 : 100}
            maxHeight={window.innerWidth > 750 ? 30 : 60}
            renderContent={() => {
              return (
                <div className="overlay-container">
                  <div className="wrapper">
                    <div
                      className={`flex-container selected`}
                      onClick={() => setSelected(2)}
                    >
                      <div className={`line-and-text selected`}>
                        <div className={`left-line third selected`} />
                        <h7 className={`tab-title selected`}>
                          ЗАВЕЖДАНЕ НА ЩЕТА
                        </h7>
                      </div>
                    </div>
                    <p className={`text-info selected`}>
                      Чрез автоматизиран чат въвеждате цялата информация за
                      събитието. Цялата комуникация със застрахователя - номер
                      на щета, допълнителна информация, начин на обезщетение и
                      други - преминава изцяло дигитално в приложението Амарант.
                    </p>
                  </div>
                </div>
              );
            }}
          />
          <Section
            image={`url(${require("../../assets/imgs/homepage-section-6-phone-4.png")})`}
            right={window.innerWidth > 900 && true}
            reversed
            title={""}
            height={window.innerWidth > 750 ? 130 : 100}
            maxHeight={window.innerWidth > 750 ? 30 : 60}
            renderContent={() => {
              return (
                <div className="overlay-container">
                  <div className="wrapper">
                    <div
                      className={`flex-container selected`}
                      onClick={() => setSelected(3)}
                    >
                      <div className={`line-and-text selected`}>
                        <div className={`left-line fourth selected`} />
                        <h7 className={`tab-title selected`}>РAЗХОДИ</h7>
                      </div>
                    </div>
                    <p className={`text-info selected`}>
                    Всички разходи, направени през приложението, се добавят
                    автоматично към справката за съответния автомобил. Имате и
                    опция за ръчно въвеждане на други разходи - гориво,
                    автомивка, смяна на гуми и други. Така разходите за всички
                    Ваши автомобили се събират на едно място с цел пълна
                    отчетност и откриване на възможности за оптимизация.
                    </p>
                  </div>
                </div>
              );
            }}
          />
          <Section
            image={`url(${require("../../assets/imgs/homepage-section-6-phone-5.png")})`}
            right={window.innerWidth > 900 && true}
            reversed
            title={""}
            height={window.innerWidth > 750 ? 130 : 100}
            maxHeight={window.innerWidth > 750 ? 30 : 60}
            renderContent={() => {
              return (
                <div className="overlay-container">
                  <div className="wrapper">
                    <div
                      className={`flex-container selected`}
                      onClick={() => setSelected(4)}
                    >
                      <div className={`line-and-text selected`}>
                        <div className={`left-line fifth selected`} />
                        <h7 className={`tab-title selected`}>КАЛЕНДАР</h7>
                      </div>
                    </div>
                    <p className={`text-info selected`}>
                      Всички важни дати за всички Ваши автомобили в един
                      календар. Тук се вписват автоматично всички данни от
                      приложението - срок за подновяване на полица, за плащане
                      на вноска, за изтичане на винетка или ГТП, но можете и
                      ръчно да добавяте събития с допълнителни файлове, бележки
                      и локация
                    </p>
                  </div>
                </div>
              );
            }}
          />
        </>
      ) : (
        <Section
          image={
            selected === 0
              ? `url(${require("../../assets/imgs/homepage-section-6-phone-1.png")})`
              : selected === 1
              ? `url(${require("../../assets/imgs/homepage-section-6-phone-2.png")})`
              : selected === 2
              ? `url(${require("../../assets/imgs/homepage-section-6-phone-3.png")})`
              : selected === 3
              ? `url(${require("../../assets/imgs/homepage-section-6-phone-4.png")})`
              : selected === 4
              ? `url(${require("../../assets/imgs/homepage-section-6-phone-5.png")})`
              : selected === 5
              ? `url(${require("../../assets/imgs/homepage-section-6-phone-6.png")})`
              : ""
          }
          title={"услуги"}
          right
          reversed
          height={101}
          renderContent={() => {
            return (
              <div className="overlay-container">
                <div className="wrapper">
                  <div
                    className={`flex-container ${selected === 0 && "selected"}`}
                    onClick={() => setSelected(0)}
                  >
                    <div
                      className={`line-and-text ${
                        selected === 0 && "selected"
                      }`}
                    >
                      <div
                        className={`left-line first ${
                          selected === 0 && "selected"
                        }`}
                      />
                      <h7
                        className={`tab-title ${selected === 0 && "selected"}`}
                      >
                        ПАРКИРАНЕ В ЗОНА
                      </h7>
                      <div
                        className={`arrowTab ${selected === 0 && "selected"}`}
                      />
                    </div>
                  </div>
                  <p className={`text-info ${selected === 0 && "selected"}`}>
                    Заплащайте синя и зелена зона директно през Амарант.
                    Приложението автоматично Ви локализира и показва
                    релевантната информация - работно време, цена и краткия
                    телефон за изпращане на смс. Натиснете бутона “плати” и
                    автоматично ще се добави този разход в общата справка за
                    автомобила.
                  </p>
                </div>
                <div className="wrapper">
                  <div
                    className={`flex-container ${selected === 1 && "selected"}`}
                    onClick={() => setSelected(1)}
                  >
                    <div
                      className={`line-and-text ${
                        selected === 1 && "selected"
                      }`}
                    >
                      <div
                        className={`left-line second ${
                          selected === 1 && "selected"
                        }`}
                      />
                      <h7
                        className={`tab-title ${selected === 1 && "selected"}`}
                      >
                        ВИДЕОРЕГИСТРАТОР
                      </h7>
                      <div
                        className={`arrowTab ${selected === 1 && "selected"}`}
                      />
                    </div>
                  </div>
                  <p className={`text-info ${selected === 1 && "selected"}`}>
                    Телефонът Ви се превръща във видеорегистратор благодарение
                    на Амарант и уникалната технология, използвана в
                    приложението. С един клик записвате това, което току-що се е
                    случило. Всичко останало автоматично се изтрива. Така имате
                    само най-важното като запис - за споделяне в социалните
                    мрежи или директно към КАТ.
                  </p>
                </div>
                <div className="wrapper">
                  <div
                    className={`flex-container ${selected === 2 && "selected"}`}
                    onClick={() => setSelected(2)}
                  >
                    <div
                      className={`line-and-text ${
                        selected === 2 && "selected"
                      }`}
                    >
                      <div
                        className={`left-line third ${
                          selected === 2 && "selected"
                        }`}
                      />
                      <h7
                        className={`tab-title ${selected === 2 && "selected"}`}
                      >
                        ЗАВЕЖДАНЕ НА ЩЕТА
                      </h7>
                      <div
                        className={`arrowTab ${selected === 2 && "selected"}`}
                      />
                    </div>
                  </div>
                  <p className={`text-info ${selected === 2 && "selected"}`}>
                    Чрез автоматизиран чат въвеждате цялата информация за
                    събитието. Цялата комуникация със застрахователя - номер на
                    щета, допълнителна информация, начин на обезщетение и други
                    - преминава изцяло дигитално в приложението Амарант.
                  </p>
                </div>
                <div className="wrapper">
                  <div
                    className={`flex-container ${selected === 3 && "selected"}`}
                    onClick={() => setSelected(3)}
                  >
                    <div
                      className={`line-and-text ${
                        selected === 3 && "selected"
                      }`}
                    >
                      <div
                        className={`left-line fourth ${
                          selected === 3 && "selected"
                        }`}
                      />
                      <h7
                        className={`tab-title ${selected === 3 && "selected"}`}
                      >
                        РAЗХОДИ
                      </h7>
                      <div
                        className={`arrowTab ${selected === 3 && "selected"}`}
                      />
                    </div>
                  </div>
                  <p className={`text-info ${selected === 3 && "selected"}`}>
                    Всички разходи, направени през приложението, се добавят
                    автоматично към справката за съответния автомобил. Имате и
                    опция за ръчно въвеждане на други разходи - гориво,
                    автомивка, смяна на гуми и други. Така разходите за всички
                    Ваши автомобили се събират на едно място с цел пълна
                    отчетност и откриване на възможности за оптимизация.
                  </p>
                </div>
                <div className="wrapper">
                  <div
                    className={`flex-container ${selected === 4 && "selected"}`}
                    onClick={() => setSelected(4)}
                  >
                    <div
                      className={`line-and-text ${
                        selected === 4 && "selected"
                      }`}
                    >
                      <div
                        className={`left-line fifth ${
                          selected === 4 && "selected"
                        }`}
                      />
                      <h7
                        className={`tab-title ${selected === 4 && "selected"}`}
                      >
                        КАЛЕНДАР
                      </h7>
                      <div
                        className={`arrowTab ${selected === 4 && "selected"}`}
                      />
                    </div>
                  </div>
                  <p className={`text-info ${selected === 4 && "selected"}`}>
                    Всички важни дати за всички Ваши автомобили в един календар.
                    Тук се вписват автоматично всички данни от приложението -
                    срок за подновяване на полица, за плащане на вноска, за
                    изтичане на винетка или ГТП, но можете и ръчно да добавяте
                    събития с допълнителни файлове, бележки и локация
                  </p>
                </div>
              </div>
            );
          }}
        />
      )}

      <Section
        image={`url(${require("../../assets/imgs/homepage-section-7.png")})`}
        first
        reversed
        invert
        height={window.innerWidth > 760 && window.innerWidth < 900 ? 120 : 101}
        title={"Потребителски център"}
        removeLine
        maxHeight={
          window.innerWidth < 900 && window.innerWidth > 760
            ? 30
            : window.innerWidth < 900 && window.innerWidth < 760 && 45
        }
        renderContent={() => {
          return (
            <>
              <p className="info-text">
                Достъпът до експертите на Амарант е вече възможно най-лесен -
                директно през приложението. Създайте тема и обсъдете всички Ваши
                въпроси, коментари или казуси в чат с нас. Ние ще Ви помогнем. И
                ние се борим всеки път да давате максимално висока оценка за
                връзката Ви с контактния център на Амарант.
              </p>
            </>
          );
        }}
      />

      {window.innerWidth < 900 ? <ReviewComponent /> : <ReviewComponent />}
    </>
  );
};

export default Home;
